import React from 'react';
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Icon,
    IconButton,
    Snackbar,
    SnackbarContent,
    TextField
} from '@material-ui/core';
import {Formik} from 'formik';
import {inject, observer} from 'mobx-react';
import {Close} from '@material-ui/icons';
import {RouteComponentProps} from "react-router";
import {UserStore} from '../../stores/UserStore';
import './Login.scss';
import validator from "validator";

const Login: React.FC<RouteComponentProps<{}>> = (props: any) => {
    const userStore = props.store.user as UserStore;
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [isOpen, setOpened] = React.useState<boolean>(false);

    return (
        <div className={"WrapDiv"}>
            <Box className={"Box"}>
                <div className={"logo"}>
                    <img src={'/Asset3.svg'} alt=""/>
                </div>
                <Card className={"card"}>
                    <div className={"headingDiv"}>
                        <h1 className={"heading"}>Login</h1>
                    </div>
                    <div className='form-container'>
                        <Formik
                            initialValues={{email: '', password: ''}}
                            validate={values => {
                                if (values.email && values.password) {
                                    return;
                                }

                                if (values.email && !validator.isEmail(values.email)) {
                                    return {
                                        email: 'Email is not in a proper format'
                                    }
                                }

                                if (!values.email) {
                                    return {
                                        email: "Email is required"
                                    };
                                }

                                if (!values.password) {
                                    return {
                                        password: "Password is required"
                                    };
                                }
                            }}
                            onSubmit={(values, actions) => {
                                actions.setSubmitting(true);
                                _closeSnackBar();

                                userStore.login(values)
                                    .then(() => {
                                        actions.setSubmitting(false);
                                        props.history.push("/")
                                    }).catch(err => {
                                    if (err.error.errorCode === 101 || err.error.errorCode === 102 || err.error.errorCode === 103 || err.error.errorCode === 104) {
                                        setErrorMessage(err.message);
                                    } else {
                                        setErrorMessage("Authentication Filed")
                                    }
                                    actions.setSubmitting(false);
                                    setOpened(true);

                                    setTimeout(() => {
                                        setOpened(false);
                                    }, 3000)
                                });
                            }}
                            render={formProps => (
                                <form onSubmit={formProps.handleSubmit}>
                                    <div className='form-content'>
                                        <TextField label={'Email'}
                                                   type={'email'}
                                                   onChange={formProps.handleChange}
                                                   onBlur={formProps.handleBlur}
                                                   value={formProps.values.email}
                                                   name={'email'}
                                                   helperText={formProps.errors.email && formProps.touched.email && formProps.errors.email}
                                                   variant="outlined"
                                        />
                                        <br/>
                                        <TextField label={'Password'}
                                                   type={'password'}
                                                   onChange={formProps.handleChange}
                                                   onBlur={formProps.handleBlur}
                                                   value={formProps.values.password}
                                                   name={'password'}
                                                   helperText={formProps.errors.password && formProps.touched.password && formProps.errors.password}
                                                   variant="outlined"
                                        />
                                        <br/>
                                        {formProps.isSubmitting ? <CircularProgress/> :
                                            <Button variant="contained" color="primary"
                                                    disabled={formProps.isSubmitting}
                                                    type={'submit'}>Login</Button>}
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </Card>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={isOpen}
                    autoHideDuration={6000}
                >
                    <SnackbarContent
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar">
                            <Icon/>
                                {errorMessage}
                        </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="close" color="inherit" onClick={_closeSnackBar}>
                                <Close/>
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                <div/>
            </Box>
            <div/>
        </div>
    );

    function _closeSnackBar() {
        setOpened(false);
    }
};


export default inject('store')(observer(Login));
