import React, {useEffect} from 'react';

import './OrganizationInfo.scss'
import Typography from "@material-ui/core/Typography";
import {CircularProgress, Icon, IconButton, Snackbar, SnackbarContent} from "@material-ui/core";
import {useHistory, useParams} from "react-router";
import {IntentModel} from "../../../models/Intent";
import IntentCard from "../../IntentCard/IntentCard";
import {inject, observer} from "mobx-react";
import CreateIntentDialog from "../../CreateIntentDialog/CreateIntentDialog";
import AddIcon from '@material-ui/icons/Add';
import AttachIntentDialog from "../../AttachIntentDialog/AttachIntentDialog";
import {Close} from "@material-ui/icons";

const OrganizationInfo: React.FC = (props: any) => {
    const rootStore = props.store!;
    const intentStore = rootStore.intent;
    const organisationStore = rootStore.organization;
    const [openIntentDialog, setOpenIntentDialog] = React.useState<boolean>(false);
    const [openCopyIntentDialog, setOpenCopyIntentDialog] = React.useState<boolean>(false);
    const [openAttachIntentDialog, setAttachIntentDialog] = React.useState<boolean>(false);
    const [intentId, setIntentId] = React.useState<number | null>(null);
    const [intentTitle, setIntentTitle] = React.useState<string>('');
    const [intentDesc, setIntentDesc] = React.useState<string>('');
    const [intentdisplayName, setintentdisplayName] = React.useState<string>('');

    const {organisationId} = useParams();
    const history = useHistory();

    useEffect(() => {
        intentStore.load();
        if(!organisationStore.currentOrganisation) {
            organisationStore.setCurrentOrganisation(organisationId);
        }
    });

    return (
        !organisationStore.currentOrganisation
            ? <CircularProgress className={'abs-center'}/>
            : <div className='organisation-info'>
                <div className='organisation-header'>
                    <Typography variant={"h4"} style={{color: '#2BA5D4'}}>
                        {organisationStore.currentOrganisation.name}
                    </Typography>
                    <Typography variant={"h6"} style={{color: "gray"}}>
                        {organisationStore.currentOrganisation.description}</Typography>
                </div>
                <div className='organisation-intents'>
                    {intentStore.isLoading && <CircularProgress className={'abs-center'}/>}
                    {organisationStore.currentOrganisation.intents ? organisationStore.currentOrganisation.intents.map(showIntent) : null}
                    <div className='intent-attach-card' onClick={() => setAttachIntentDialog(true)}>
                        <Typography variant={"h6"}><AddIcon/>Attach Intent</Typography>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={intentStore.isOpen}
                    autoHideDuration={600}
                >
                    <SnackbarContent
                        aria-describedby="client-snackbar"
                        message={
                            <span id="client-snackbar">
                            <Icon/>
                                {intentStore.errorMessage}
                        </span>
                        }
                        action={[
                            <IconButton key="close" aria-label="close" color="inherit"
                                        onClick={intentStore.closeSnackBar}>
                                <Close/>
                            </IconButton>,
                        ]}
                    />
                </Snackbar>
                <CreateIntentDialog
                    open={openIntentDialog || openCopyIntentDialog}
                    onClose={
                        () => {
                            setOpenIntentDialog(false);
                            setOpenCopyIntentDialog(false);
                            organisationStore.errorMessage = "";
                        }
                    }
                    title={openCopyIntentDialog ? 'Copy Intent' : 'Update Intent'}
                    isUpdate={openIntentDialog}
                    isCopy={openCopyIntentDialog}
                    isCreate={false}
                    intentId={intentId}
                    intentTitle={!openCopyIntentDialog ? intentTitle : ''}
                    intentDesc={!openCopyIntentDialog ? intentDesc : ''}
                    intentDisplayName={!openCopyIntentDialog ? intentdisplayName : ''}
                />
                <AttachIntentDialog open={openAttachIntentDialog}
                                    onClose={() => setAttachIntentDialog(false)}
                                    availableIntents={rootStore.currentOrganisationIntents}
                />
            </div>
    );

    function editIntent(event: Event, intentId: number, title: string, desc: string,displayName:string) {
        setOpenIntentDialog(true);
        setIntentId(intentId);
        setIntentTitle(title);
        setIntentDesc(desc);
        setintentdisplayName(displayName);
    }

    function copyIntent(event: Event, intentId: number, title: string, desc: string,displayName:string) {
        setOpenCopyIntentDialog(true);
        setIntentId(intentId);
        setIntentTitle(title);
        setIntentDesc(desc);
        setintentdisplayName(displayName);
    }

    function showIntent(intent: IntentModel, index: number) {
        return <IntentCard
            isOrgIntent={true}
            key={intent.id}
            intentTitle={intent.display_name}
            intentDesc={intent.description}
            onDelete={(id) => {
                detachIntent(intent);
            }}
            onAdd={() => history.push(`/organization/${organisationId}/intents/${intent.id}/questions`)}
            intent={intent}
            onEditIntent={editIntent}
            onCopyIntent={copyIntent}
        />
    }

    function detachIntent(intent: IntentModel) {
        organisationStore.detachIntent(intent, +organisationId!)
    }
};

export default inject('store')(observer(OrganizationInfo));
