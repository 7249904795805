import React from 'react';
import {useHistory} from "react-router";

export const NotFound: React.FC = () => {
    const history = useHistory();
    return (
        <div style={{
            display: "flex", justifyContent: "center", alignItems: "center",
            flexDirection: "column", height: 600
        }}>
            <h1>This page doesn't exists</h1>
            <span style={{cursor: "pointer", color: '#2BA5D4', textDecoration: "underline"}}
                  onClick={() => history.replace('/')}>Go Back</span>
        </div>
    );
};
