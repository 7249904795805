import React from 'react';
import {Search} from '@material-ui/icons';
import {TextField} from '@material-ui/core';
import './SearchBar.scss';
import {inject, observer} from 'mobx-react';
import {RootStore} from "../../../stores/RootStore";


interface SearchBarProps {
    placeholder?: string;
    store?: RootStore;
    onChange: (query: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = (props: SearchBarProps) => {
    return (
        <section className='search-bar'>
            <Search/>
            <TextField className={"search-field"} placeholder={props.placeholder || 'Search...'}
                       onChange={(event) => props.onChange(event.target.value)}/>
        </section>
    );

};

export default inject('store')(observer(SearchBar));
