import React from 'react';
import {Route, RouteComponentProps, Switch} from 'react-router';
import FormBuilder from '../FormBuilder/FormBuilder';
import Intent from "../Intent/Intent";
import {NotFound} from '../NotFound/NotFound';
import Organization from "../Organization/Organization";
import './Home.scss';
import {Typography} from "@material-ui/core";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import OrganizationInfo from "../Organization/OrganizationInfoPage/OrganizationInfo";

const Home: React.FC<RouteComponentProps<{}>> = (props: any) => {

    const logoutHandler = () => {
        props.history.push('/logout');
    };


    return (
        <div className={'main'}>
            <section className={'app-bar'}>
                <section className={'logo'}>
                    <img src={'/Asset3.svg'} alt="" onClick={() => props.history.push("/")}/>
                    <section className={'list'}>
                        <Typography variant={"h5"}
                                    className={window.location.pathname === '/' ? 'active' : ''}
                                    onClick={() => props.history.push("/")}>
                            Organisations</Typography>
                        <Typography variant={"h5"}
                                    className={window.location.pathname === '/intents' ? 'active' : ''}
                                    onClick={() => props.history.push("/intents")}>
                            Intents</Typography>
                    </section>
                    <div/>
                </section>
                <div onClick={logoutHandler} className={'logOut-btn'}>
                    <PowerSettingsNewIcon fontSize={"small"}/>Log Out
                </div>
            </section>

            <section className={'panel'}>
                <Switch>
                    <Route path={'/'} exact component={Organization}/>
                    <Route path={'/intents'} exact component={Intent}/>
                    <Route path={'/organization/:organisationId'} exact component={OrganizationInfo}/>
                    <Route path={'/organization/:organisationId/intents/:intentId/questions'} exact component={FormBuilder}/>
                    <Route path={'/:intentId/questions'} exact component={FormBuilder}/>
                    <Route component={NotFound}/>
                </Switch>
            </section>
        </div>
    );
};

export default Home;
