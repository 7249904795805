import {IntentModel} from "../models/Intent";
import {apiService} from "./ApiService";
import {EntityIdentifier} from "../stores/Store";
import {QuestionModel} from "../models/Question";

class IntentService {
    static getInstance(): IntentService {
        return new IntentService()
    }

    get(id: EntityIdentifier): Promise<IntentModel> {
        return apiService.get<{ data: any }>(`/intents/${id}`).then(res => IntentModel.fromJson(res.data) as IntentModel);
    }

    list(): Promise<IntentModel[]> {
        return apiService.get<{ data: any[] }>('/intents').then(response => response.data.map(j => IntentModel.fromJson(j) as IntentModel));
    }

    create(data: {
        intent: string,
        description: string,
        organisationId?: number | null,
        is_base_intent: boolean
    }): Promise<IntentModel> {
        return apiService.post<{ data: any }>('/intents', data).then(response => IntentModel.fromJson(response.data) as IntentModel)
    }

    copy(data: {
        id: number,
        intent: string,
        description: string,
        organisationId?: number | null
    }): Promise<IntentModel> {
        return apiService.post<{ data: any }>('/intents/copy', data).then(response => IntentModel.fromJson(response.data) as IntentModel)
    }

    loadIntentQuestions(id: EntityIdentifier, orgId: number | undefined): Promise<QuestionModel[]> {
        if (!orgId) {
            return apiService.get<{ data: any[] }>(`/intents/${id}/questions`)
                .then(res => res.data.map(j => QuestionModel.fromJson(j) as QuestionModel))
        }
        return apiService.get<{ data: any[] }>(`/organizations/${orgId}/intents/${id}/questions`)
            .then(res => res.data.map(j => QuestionModel.fromJson(j) as QuestionModel))
    }

    update(id: number, data: {
        intent: string,
        description: string,
        display_name: string,
        organizationId?: number | null
    }): Promise<IntentModel> {
        return apiService.put<{ data: any }>(`/intents/${id}`, data).then(res => IntentModel.fromJson(res.data) as IntentModel)
    }

    delete(id: EntityIdentifier): Promise<void> {
        return apiService.delete(`/intents/${id}`)
    }
}

export const intentService = IntentService.getInstance();
