import {observer} from 'mobx-react';
import React from 'react';
import {Avatar, CardActions, CircularProgress, Tooltip, Typography,} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import {EntityIdentifier} from "../../stores/Store";
import EditIcon from '@material-ui/icons/Edit';
import {OrganizationModel} from "../../models/Organization";
import "./OrganizationCard.scss";
import CodeIcon from '@material-ui/icons/Code';
import DescriptionIcon from '@material-ui/icons/Description';
import Switch from "@material-ui/core/Switch";
import CreateOrganizationDialog from "../CreateOrganizationDialog/CreateOrganizationDialog";

interface OrganizationCardProps {
    organizationTitle: string;
    organizationLogo: string | undefined;
    organizationDesc: string | undefined;
    organizationTheme: string | undefined;
    organization: OrganizationModel;
    onDelete: (id: EntityIdentifier) => void;
    onAdd: () => void;
    onGetWidgetCode: Function;
    onGetTextWidgetCode: Function;
    onToggleEnableMessageButton: Function;
    updatingOrganizationId: number | null;
    organizationIsSaving: boolean;
}

const OrganizationCard: React.FC<OrganizationCardProps> = (props: OrganizationCardProps) => {
    const organization = props.organization;
    const [openOrganizationDialog, setOpenOrganizationDialog] = React.useState<boolean>(false);

    return (
        <div>
            <section>
                <section className={'organization-card'}>
                    {organization.deleting && <div className={'delete-spinner'}><CircularProgress/></div>}
                    <section className={'cLabel'}>
                        <section className={'avtar'}>
                            <Avatar alt="Remy Sharp" src={props.organizationLogo} className={'avatar'}/>
                        </section>
                        <div className='organisation-details'>
                            <Typography className={'organisation-heading'} variant={"h6"}>
                                {props.organizationTitle ? props.organizationTitle.length > 20 ?
                                    props.organizationTitle.slice(0, 20) + '...' : props.organizationTitle : null}</Typography>
                            <Typography variant={"caption"}>
                                {props.organizationDesc ? props.organizationDesc.length > 20 ?
                                    props.organizationDesc.slice(0, 20) + '...' : props.organizationDesc : null}
                            </Typography>
                        </div>
                    </section>
                    <section style={{display: "flex", justifyContent: "center"}}>
                        <div
                            className={'btn2'}
                            onClick={props.onAdd}>
                            View Organisation
                        </div>
                    </section>
                    <section className={'info'}>
                        <CardActions>
                            {props.organizationIsSaving && props.updatingOrganizationId === organization.id ?
                                <CircularProgress size={20}/>
                                :
                                <Tooltip title={'Enable Message Button'}>
                                    <Switch
                                        checked={!!organization.enable_message_button}
                                        onChange={() => {
                                            props.onToggleEnableMessageButton(organization.id, !organization.enable_message_button)
                                        }}
                                        color="primary"
                                        name="checkedB"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                </Tooltip>
                            }
                            <Tooltip  title={'Text Widget Script'}>
                                <DescriptionIcon  cursor={'pointer'} color={'primary'} onClick={()=>{props.onGetTextWidgetCode(organization.id);}} />
                            </Tooltip>
                            <Tooltip  title={'Widget Script'}>
                                <CodeIcon  cursor={'pointer'} color={'primary'} onClick={()=>{props.onGetWidgetCode(organization.id);}} />
                            </Tooltip>
                            <Tooltip title={'Edit Organization'}>
                                <EditIcon onClick={() => {
                                    setOpenOrganizationDialog(true);
                                }} cursor={'pointer'}/>
                            </Tooltip>
                            <Tooltip title={'Delete Organization'}>
                                <Delete cursor={'pointer'} color={'error'}
                                        onClick={() => organization.deleting || props.onDelete(organization.id)}/>
                            </Tooltip>
                        </CardActions>
                    </section>
                </section>
            </section>
            {openOrganizationDialog && <CreateOrganizationDialog
                onClose={() => {
                    setOpenOrganizationDialog(false);
                }}
                title={"Update Organisation"}
                isUpdate={true}
                organizationId={organization.id}
                organizationName={organization.name}
                organizationDesc={organization.description}
                organizationTheme={organization.theme || ""}
                organizationLogoUrl={organization.logo}
                organizationPhoneNumber={organization.phone_number}
                organizationEnableMessageButton={organization.enable_message_button}
            />}
        </div>
    );
};

export default observer(OrganizationCard);
