import React from 'react';
import {Box, TextField} from '@material-ui/core';
import {QuestionMeta, TextQuestionMeta} from '../../../models/Question';
import {observer} from 'mobx-react';

export interface QuestionBuilderProps {
    meta: QuestionMeta;
    onChange: (value: QuestionMeta) => void;
}

const TextInputBuilder: React.FC<QuestionBuilderProps> = (props: QuestionBuilderProps) => {
    const meta = _getMeta();
    return <Box display={'flex'} flexDirection={'column'}>
        <TextField
            value={meta.placeholder}
            onChange={(e) => {
                meta.placeholder = e.target.value;
            }}
            onBlur={() => _onBlur(meta)}
            multiline
            placeholder={'Hint Goes here...'}/>
    </Box>;

    function _getMeta(): TextQuestionMeta {
        return props.meta as TextQuestionMeta;
    }

    function _onBlur(meta: QuestionMeta) {
        props.onChange(meta);
    }
};

export default observer(TextInputBuilder);
