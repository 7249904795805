import React from 'react';
import {RouteComponentProps} from "react-router";
import {AUTH_TOKEN} from "../../services/ApiService";

const Logout: React.FC<RouteComponentProps<{}>> = (props: any) => {
    React.useEffect(() => {
        localStorage.removeItem(AUTH_TOKEN);
        console.log("**********");
        props.history.replace('/');
    });

    return (
        <></>
    )
};


export default Logout;