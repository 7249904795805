import React, {useEffect} from 'react';
import {Box, Card, CircularProgress, Icon, IconButton, Snackbar, SnackbarContent} from '@material-ui/core';
import {Add, Check, Close, Input, Menu, MenuOpen, EditLocation} from '@material-ui/icons';
import EmailIcon from '@material-ui/icons/Email';
import {SpeedDial, SpeedDialAction} from '@material-ui/lab';
import QuestionCard from './QuestionCard';
import {capitalize} from 'lodash';
import {inject, observer} from 'mobx-react';
import {
    FieldTypes,
    isBinaryQuestionMeta,
    isChoiceQuestionMeta,
    isTextQuestionMeta,
    QuestionMeta,
    QuestionModel
} from '../../models/Question';
import {EntityIdentifier} from '../../stores/Store';
import {RouteComponentProps, useParams} from "react-router";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import './FormBuilder.scss'
import Typography from "@material-ui/core/Typography";
import InfoIcon from '@material-ui/icons/Info';
import {IntentModel} from "../../models/Intent";

const FormBuilder: React.FC<RouteComponentProps<{}>> = (props: any) => {
    const questionStore = props.store.question;
    const intentStore = props.store.intent;
    const {intentId} = useParams<{ intentId: string }>();
    const {organisationId} = useParams<{ organisationId: string }>();
    const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);

    // let intent = useMemo(() => intentStore.get(+intentId), [intentId]);
    let intent = intentStore.entities.find((e: IntentModel) => e.id === +intentId)!;

    useEffect(() => {
        if (!intent) {
            intentStore.loadIntent(+intentId).then(() => {
                    // eslint-disable-next-line
                    intent = intentStore.entities.find((e: IntentModel) => e.id === +intentId)!;
                    if(organisationId) {
                        intentStore.loadQuestions(+intentId, organisationId);
                    }else {
                        intentStore.loadQuestions(+intentId, undefined);
                    }
                }
            )
        } else {
            if(organisationId) {
                intentStore.loadQuestions(+intentId, organisationId);
            }else {
                intentStore.loadQuestions(+intentId, undefined);
            }
        }
        // intentStore.loadIntent(+intentId);
        // intentStore.loadQuestions(+intentId);
    }, []);

    return (
        <>
            {(intent || intentStore.isLoadingQuestions) ?
                (<>
                    {intent &&
                    <div className={'question-header'}>
                        <IconButton onClick={() => props.history.goBack()}>
                            <ArrowBackIosIcon fontSize={"small"}/>
                            <Typography variant={"h6"}>Back</Typography>
                        </IconButton>
                        {
                            intentStore.isLoading ? <CircularProgress/> : <div className='intent-info'>
                                <Typography variant={"h5"} style={{color: '#2BA5D4'}}>{intent.intent}</Typography>
                                <Typography variant={"caption"}>{intent.description}</Typography>
                            </div>
                        }
                        <div/>
                    </div>}
                    <Box display={'flex'} flexDirection={'column'} className={'wrap'}>
                        <Box display={'flex'} justifyContent={'center'}>
                            <Box margin={5} display={'flex'} flexDirection={'column'}
                                 justifyContent={'center'} alignItems={'center'}>
                                {(getUpdatedQuestionsList() || []).length ? (getUpdatedQuestionsList() || []).map(_showQuestion)
                                    : !intentStore.isLoadingQuestions ?
                                        <Typography variant={"h6"} style={{color: '#2BA5D4'}}>No Question
                                            Available</Typography> : null}
                                {intentStore.isLoadingQuestions || questionStore.isAdding ?
                                    <CircularProgress className={'abs-center'}/> : <> </>}
                            </Box>
                        </Box>
                    </Box>
                    <SpeedDial style={{position: 'fixed', bottom: '60px', right: '40px'}}
                               ariaLabel="SpeedDial example"
                               icon={<Add htmlColor={'white'}/>}
                               onClose={_togglePopover}
                               onOpen={_togglePopover}
                               open={isPopoverOpen}
                    >
                        {Object.values(FieldTypes).map((field, index) => (
                            <SpeedDialAction
                                key={_getKey(field, index)}
                                icon={_getIcon(field)}
                                title={_getName(field)}
                                onClick={() => _addQuestion(field)}
                            />
                        ))}
                    </SpeedDial>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={questionStore.errorMessage.length > 0}
                        autoHideDuration={6000}
                    >
                        <SnackbarContent
                            aria-describedby="client-snackbar"
                            message={
                                <span id="client-snackbar">
                            <Icon/>
                                    {questionStore.errorMessage}
                        </span>
                            }
                            action={[
                                <IconButton key="close" aria-label="close" color="inherit"
                                            onClick={questionStore.closeSnackBar}>
                                    <Close/>
                                </IconButton>,
                            ]}
                        />
                    </Snackbar>
                </>)
                :
                (
                    <div className={'wrapper'}>
                        <CircularProgress/>
                    </div>
                )
            }
        </>
    );

    function getUpdatedQuestionsList() {
        return (questionStore.entities.filter((q: QuestionModel) => {
            if(organisationId) {
                return (!q.deleted && q.intent_id === +intentId && q.organization_id === +organisationId);
            }
            return (!q.deleted && q.intent_id === +intentId && q.organization_id === null)
        })
            .sort((a: any, b: any) => a.order - b.order))
    }

    function _getIcon(field: FieldTypes) {
        switch (field) {
            case FieldTypes.INPUT:
                return <Input/>;
            case FieldTypes.BINARY:
                return <Check/>;
            case FieldTypes.SINGLE_CHOICE:
                return <Menu/>;
            case FieldTypes.MULTI_CHOICE:
                return <MenuOpen/>;
            case FieldTypes.ADDRESS:
                return <EditLocation/>
            case FieldTypes.EMAIL:
                return <EmailIcon/>

        }
    }

    function _getName(field: FieldTypes): string {
        return field.toString().split('_').map(elem => capitalize(elem)).reduce((value1: string, value2: string) => value1 + ' ' + value2).trim();
    }

    function _addQuestion(field: FieldTypes, metaObj?: QuestionMeta) {
        let meta = {};
        switch (field) {
            case FieldTypes.INPUT:
                meta = {
                    placeholder: metaObj && isTextQuestionMeta(metaObj) ? metaObj.placeholder : 'Type your answer..'
                };
                break;
            case FieldTypes.ADDRESS:
                meta = {
                    placeholder: metaObj && isTextQuestionMeta(metaObj) ? metaObj.placeholder : 'Type your answer..'
                };
                break;
            case FieldTypes.EMAIL:
                meta = {
                    placeholder: metaObj && isTextQuestionMeta(metaObj) ? metaObj.placeholder : 'Type your answer..'
                };
                break;
            case FieldTypes.BINARY:
                meta = {
                    accept_text: metaObj && isBinaryQuestionMeta(metaObj) ? metaObj.accept_text : 'Yes',
                    reject_text: metaObj && isBinaryQuestionMeta(metaObj) ? metaObj.reject_text : 'No'
                };
                break;
            case FieldTypes.SINGLE_CHOICE:
                meta = {
                    choices: metaObj && isChoiceQuestionMeta(metaObj) ? metaObj.choices : ['side', 'front', 'back'],
                    multi: false
                };
                break;
            case FieldTypes.MULTI_CHOICE:
                meta = {
                    choices: metaObj && isChoiceQuestionMeta(metaObj) ? metaObj.choices : ['side', 'front', 'back'],
                    multi: true
                };
                break;
        }
        if (metaObj) {
            return meta
        } else {
            const orgId = organisationId ? organisationId : null;
            questionStore.add('', field, +intentId!, meta as QuestionMeta, orgId).then((_hidePopover));
        }
    }

    function _hidePopover() {
        window.scrollTo(0, document.body.scrollHeight);
        setIsPopoverOpen(false);
    }

    function _togglePopover() {
        setIsPopoverOpen(!isPopoverOpen);
    }

    function _getKey(field: FieldTypes, index: number) {
        return `${field}${index}`;
    }

    function _showQuestion(question: QuestionModel, index: number) {
        const key = _getKey(question.type, index);

        if (question.intent_id === +props.match.params.intentId) {
            return (
                <Card key={key} elevation={3}
                      className={question.isValid() ? 'question-card' : 'question-card red-border'}>
                    {question.deleting && <div className={'spinner'}><CircularProgress/></div>}
                    <Box m={2} display={'flex'}>
                        <b style={{marginRight: '12px', paddingTop: '6px', paddingBottom: '7px'}}>
                            {'0' + (index + 1)}
                        </b>
                        <QuestionCard
                            isUpdating={questionStore.isUpdating[question.id]}
                            onDelete={questionStore.delete.bind(questionStore)}
                            onDrop={(p: EntityIdentifier) => _handleQuestionDrop(p, question.id)}
                            onUpdate={(question: QuestionModel) =>
                                questionStore.update(question.id, question.title, question.type, question.required, question.slot, _addQuestion(question.type, question.meta))}
                            question={question}
                        />
                    </Box>
                    {question.isValid() ? <></> :
                        <Typography variant={"caption"} color={"error"} style={{display: "flex", alignItems: "center"}}>
                            <InfoIcon fontSize={"small"} style={{marginRight: 1}}/>Please Update required
                            fields</Typography>
                    }
                </Card>
            )
        } else {
            return null;
        }
    }

    function _handleQuestionDrop(fromIndex: EntityIdentifier, toIndex: EntityIdentifier) {
        questionStore.swapOrder(fromIndex, toIndex);
    }
};

export default inject('store')(observer(FormBuilder));
