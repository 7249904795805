import Model from './Model';
import {IntentStore} from '../stores/IntentStore';
import {QuestionModel} from './Question';
import {observable} from 'mobx';
import {OrganizationModel} from "./Organization";

export class IntentModel extends Model {
    static _store: IntentStore;

    @observable id!: number;
    @observable organization_id!: number | null;
    @observable intent!: string;
    @observable description?: string;
    @observable created_at!: string;
    @observable updated_at!: string;
    @observable display_name!: string;
    @observable isDetaching: boolean = false;
    @observable base_intent: boolean = false;

    @observable questions?: QuestionModel[];
    @observable organization?: OrganizationModel;

    getId(): string | number {
        return this.id;
    }
}
