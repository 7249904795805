import Store, {EntityIdentifier} from './Store';
import {IntentModel} from '../models/Intent';
import {action, computed, observable, runInAction} from 'mobx';
import {intentService} from "../services/IntentService";
import {OrganizationStore} from './OrganizationStore';
import {OrganizationModel} from "../models/Organization";

export class IntentStore extends Store<IntentModel> {
    private static _instance: IntentStore;

    @observable isLoading: boolean = false;
    @observable isLoaded: boolean = false;
    @observable isSaving: boolean = false;
    @observable isOpen: boolean = false;
    @observable isLoadingQuestions: boolean = false;
    @observable errorMessage: string = "";
    @observable searchString: string = "";

    private constructor() {
        super();
        IntentModel._store = this;
    }

    @computed
    get intents() {
        return this.entities.filter((i: IntentModel) => !i.deleted);
    }

    @computed
    get left_intents() {
        return this.entities.filter((i: IntentModel) => !i.deleted);
    }

    static getInstance() {
        if (!this._instance) {
            this._instance = new IntentStore();
        }

        return this._instance;
    }

    @action
    load() {
        if (!this.isLoaded) {
            this.isLoading = true;
            intentService.list().then(response => {
                runInAction(() => {
                    this.isLoading = false;
                    this.isLoaded = true;
                });
            }).catch(err => {
                runInAction(() => {
                    this.isSaving = false;
                    this.isLoading = false;
                    this.isOpen = true;
                    if (err.error.errorCode === 201 || err.error.errorCode === 202) {
                        this.errorMessage = err.message;
                    } else {
                        this.errorMessage = "Some Problem Occurred Unable to load Intents";
                    }
                });

                throw err;
            });
        }
    }

    @action
    loadIntent(intentId: number) {
        this.isLoading = true;
        return intentService.get(intentId).then(response => {
            runInAction(() => {
                this.isLoading = false;
            });
        }).catch(err => {
            runInAction(() => {
                this.isSaving = false;
                this.isLoading = false;
                this.isOpen = true;
                if (err.error.errorCode === 201 || err.error.errorCode === 202) {
                    this.errorMessage = err.message;
                } else {
                    this.errorMessage = "Some Problem Occurred Unable to load Intent";
                }
            });

            throw err;
        });
    }

    @action
    loadQuestions(intentId: EntityIdentifier, organizationId: number | undefined) {
        this.isLoadingQuestions = true;

        const intent = this.get(intentId)!;
        intentService.loadIntentQuestions(intentId, organizationId).then(res => {
            runInAction(() => {
                this.isLoadingQuestions = false;
                intent.questions = res;
            })

        }).catch(err => {
            console.log(err.message);
            if (err.error.errorCode === 201 || err.error.errorCode === 202) {
                this.errorMessage = err.message;
            } else {
                this.errorMessage = "Some Problem Occurred Unable to load Intents";
            }
        });

    }

    @action
    create(intent: string, description: string, display_name:string, isBaseIntent: boolean, organizationId ?: number | null) {
        this.isSaving = true;

        let body: any = {intent, description, display_name, is_base_intent: isBaseIntent};
        if (organizationId) {
            body = {...body, organization_id: +organizationId}
        }

        return intentService.create(body).then(response => {
            runInAction(() => {
                this.isSaving = false;
                if (isBaseIntent) {
                    // eslint-disable-next-line
                    OrganizationStore.getInstance().organizations.map((org: OrganizationModel) => {
                        // if (org.intents && org.intents.length > 0) {
                            org.intents!.push(IntentModel.fromJson(response) as IntentModel)
                        // }
                    })
                } else if (organizationId) {
                    OrganizationStore.getInstance().setCurrentOrganisation(organizationId);
                    OrganizationStore.getInstance().currentOrganisation!.intents!.push(IntentModel.fromJson(response) as IntentModel);
                }

            });
        }).catch(err => {
            runInAction(() => {
                this.isSaving = false;
                this.isLoading = false;
                this.isOpen = true;
                if (err.error.errorCode === 201 || err.error.errorCode === 202) {
                    this.errorMessage = err.message;
                } else {
                    this.errorMessage = "Some Problem Occurred while creating Intent";
                }
            });

            setTimeout(() => {
                this.closeSnackBar();
            }, 3000);

            throw err;
        });
    }

    @action
    copy(intentId: number, intent: string, display_name:string, description: string, organizationId ?: number | null) {
        this.isSaving = true;

        let body: any = {intent, description,display_name, intent_id: +intentId};

        if (organizationId) {
            body = {...body, organization_id: +organizationId}
        }

        return intentService.copy(body).then(response => {
            runInAction(() => {
                this.isSaving = false;
                if (organizationId) {
                    OrganizationStore.getInstance().setCurrentOrganisation(organizationId);
                    OrganizationStore.getInstance().currentOrganisation!.intents!.push(IntentModel.fromJson(response) as IntentModel);
                }
            });
        }).catch(err => {
            runInAction(() => {
                this.isSaving = false;
                this.isLoading = false;
                this.isOpen = true;
                if (err.error.errorCode === 201 || err.error.errorCode === 202) {
                    this.errorMessage = err.message;
                } else {
                    this.errorMessage = "Some Problem Occurred while copying Intent";
                }
            });

            setTimeout(() => {
                this.closeSnackBar();
            }, 3000);

            throw err;
        });
    }

    @action
    update(intentId: number, intent: string, display_name:string, description: string, organizationId ?: number | null) {
        this.isSaving = true;

        let body: any = {intent, description, display_name};
        if (organizationId) {
            body = {...body, organization_id: +organizationId}
        }

        return intentService.update(intentId, body).then(response => {
            runInAction(() => {
                this.isSaving = false;
            });
        }).catch(err => {
            runInAction(() => {
                this.isSaving = false;
                this.isLoading = false;
                this.isOpen = true;
                if (err.error.errorCode === 201 || err.error.errorCode === 202) {
                    this.errorMessage = err.message;
                } else {
                    this.errorMessage = "Some Problem Occurred while updating Intent";
                }
            });

            setTimeout(() => {
                this.closeSnackBar();
            }, 3000);

            throw err;
        });
    }

    @action
    delete(id: EntityIdentifier) {
        const intent = this.get(id)!;
        intent.deleting = true;

        return intentService.delete(intent.id).then(resp => {
            runInAction(() => {
                intent.deleting = false;
                intent.deleted = true;
            })
        }).catch(err => {
            runInAction(() => {
                intent.deleting = false;
                this.isOpen = true;
                if (err.error.errorCode === 201 || err.error.errorCode === 202) {
                    this.errorMessage = err.message;
                } else {
                    this.errorMessage = "Some Problem Occurred while deleting Intent";
                }
            });
            throw err;
        });
    }

    @action
    closeSnackBar = () => {
        this.isOpen = false;
        this.errorMessage = "";
    };


    @action
    updateSearchString = (s: string) => {
        this.searchString = s;
    }
}

