import Store, {EntityIdentifier} from './Store';
import {FieldTypes, QuestionMeta, QuestionModel} from '../models/Question';
import {action, computed, observable, runInAction} from 'mobx';
import {questionService} from "../services/QuestionService";

export class QuestionStore extends Store<QuestionModel> {
    private static _instance: QuestionStore;

    @observable isAdding: boolean = false;
    @observable isUpdating: { [questionId: number]: boolean } = {};
    @observable isOpen: boolean = false;
    @observable errorMessage: string = "";

    private constructor() {
        super();
        QuestionModel._store = this;
    }

    @computed
    get questions() {
        return this.entities.filter((q: QuestionModel) => !q.deleted).sort((a, b) => a.order - b.order);
    }

    static getInstance(): QuestionStore {
        if (!this._instance) {
            this._instance = new QuestionStore();
        }

        return this._instance;
    }

    @action
    add(title: string, type: FieldTypes, intent: EntityIdentifier, meta: QuestionMeta, orgId: number | null) {
        this.isAdding = true;

        let body: any = {
            intent_id: intent,
            question: title,
            meta: {
                ...meta,
                type
            },
            type
        };

        if(orgId) {
            body = {...body, organization_id: +orgId}
        }

        return questionService.add(body).then(response => {
            runInAction(() => {
                this.isAdding = false;
            });
        }).catch(err => {
            runInAction(() => {
                this.isAdding = false;
                this.isOpen = true;
                this.errorMessage = err.message;
            });

            throw err;
        });
    }

    @action
    update(questionId: number, title: string, type: string, required: boolean, slot: string, meta?: QuestionMeta) {
        this.isUpdating[questionId] = true;
        const metaObj = {...meta, type: type};

        if (!slot) {
            slot = ""
        }

        let body: any = {
            question: title.trim(),
            required: required,
            slot: slot.trim(),
            meta: metaObj
        };

        return questionService.update(questionId, body).then(res => {
            runInAction(() => {
                this.isUpdating[questionId] = false;
            });
        }).catch(err => {
            runInAction(() => {
                this.isUpdating[questionId] = false;
                this.isOpen = true;
                this.errorMessage = err.message;

                throw err;
            });
        });
    }

    @action
    delete(id: EntityIdentifier) {
        const question = this.get(id)!;
        question.deleting = true;

        return questionService.delete(question.id).then(res => {
            runInAction(() => {
                question.deleting = false;
                question.deleted = true;
            })
        }).catch(err => {
            runInAction(() => {
                question.deleting = false;
                this.isOpen = true;
                this.errorMessage = err.message;
            });
            throw err;
        });
    }

    @action
    closeSnackBar = () => {
        runInAction(() => {
            this.isOpen = false;
            this.errorMessage = "";
        });
    };

    @action
    swapOrder(fromQuestionId: EntityIdentifier, toQuestionId: EntityIdentifier) {
        const fromQuestion = this.get(fromQuestionId)!;
        const toQuestion = this.get(toQuestionId)!;

        const fromQuestionOrder = fromQuestion.order;
        questionService.swap(fromQuestionId,fromQuestion.order,toQuestionId,toQuestion.order).then(()=>{
            fromQuestion.order = toQuestion.order;
            toQuestion.order = fromQuestionOrder;
        }).catch(err=>console.log(err));
    }
}
