import React, {KeyboardEvent} from 'react';
import {Box, Checkbox, TextField} from '@material-ui/core';
import {useDrag, useDrop} from 'react-dnd';
import {Delete} from '@material-ui/icons';
import {QuestionMeta, QuestionModel} from '../../models/Question';
import {EntityIdentifier} from '../../stores/Store';
import {observer} from 'mobx-react';
import {runInAction} from 'mobx';
import './QuestionCard.scss';

interface QuestionProps {
    question: QuestionModel;
    isUpdating?: boolean;
    onDrop: (id: EntityIdentifier) => void;
    onDelete: (id: EntityIdentifier) => void;
    onUpdate: (question: QuestionModel) => void;
}

const QuestionCard: React.FC<QuestionProps> = (props: QuestionProps) => {
    const question = props.question;

    // eslint-disable-next-line
    const [{opacity}, dragRef] = useDrag({
        item: {type: 'all', question: question},
        collect: (monitor: any) => ({
            opacity: monitor.isDragging() ? 0.5 : 1,
        }),
    });

    // eslint-disable-next-line
    const [{isOver, canDrop}, dropRef] = useDrop({
        accept: 'all',
        canDrop: () => true,
        drop: (object: any) => props.onDrop(object.question.id),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    return _wrapInField(React.createElement(question.getBuilderComponent(), {
        meta: question.meta,
        onChange: (meta: QuestionMeta) => {
            runInAction(() => question.meta = meta);
            props.onUpdate(question);
        }
    }));

    function _wrapInField(element: React.ReactNode) {
        return (
            <div className={'overlay'}>
                <div ref={dropRef}>
                    <div ref={dragRef}>
                        <Box display={'flex'} alignItems={'center'}>
                            <Box
                                display={'flex'} flexDirection={'column'} mr={1} className={'internal-box'}>
                                <Delete className={'delete-button'}
                                        cursor={'pointer'} color={'error'}
                                        onClick={() => question.deleting || props.onDelete(question.id)}/>
                                <TextField
                                    helperText={'* This field is required'}
                                    disabled={props.isUpdating}
                                    value={question.title || ''}
                                    onChange={(event) => {
                                        question.title = event.target.value
                                    }}
                                    onBlur={_onBlur}
                                    onKeyDown={_onKeyDown}
                                    style={{marginBottom: '10px'}}
                                    placeholder={'Question goes here...'}
                                    multiline
                                />
                                {element}
                                <div style={{
                                    display: "flex", justifyContent: "space-between"
                                    , alignItems: "center", marginTop: 16
                                }}>
                                    <div style={{display: "flex"}}>
                                        <Checkbox
                                            edge="start"
                                            checked={question.required}
                                            tabIndex={-1}
                                            onChange={_handleRequiredCheckbox}
                                            color={"primary"}
                                            disableRipple
                                            inputProps={{'aria-labelledby': "Required"}}
                                        />
                                        <p style={{alignSelf: "center"}}>Required</p>
                                    </div>
                                    <TextField placeholder={'Slot'}
                                               value={question.slot || ''}
                                               disabled={props.isUpdating}
                                               onBlur={_onBlur}
                                               onKeyDown={_onKeyDown}
                                               onChange={(event) => {
                                                   question.slot = event.target.value
                                               }}
                                               helperText={'* This field is required'}/>
                                </div>
                            </Box>
                        </Box>
                    </div>
                </div>
            </div>);
    }

    function _onKeyDown(event: KeyboardEvent<HTMLInputElement>) {
        if (event.keyCode === 13) {
            props.onUpdate(question);
        }
    }

    function _onBlur() {
        props.onUpdate(question);
    }

    function _handleRequiredCheckbox() {
        question.required = !question.required;
        props.onUpdate(question);
    }
};

export default observer(QuestionCard);
