import Model from './Model';
import {observable} from 'mobx';
import {QuestionStore} from '../stores/QuestionStore';
import {FunctionComponent} from 'react';
import TextInputBuilder, {QuestionBuilderProps} from '../components/FormBuilder/ElementBuilders/TextInputBuilder';
import BinaryInputBuilder from '../components/FormBuilder/ElementBuilders/BinaryInputBuilder';
import ChoiceInputBuilder from '../components/FormBuilder/ElementBuilders/ChoiceInputBuilder';

export enum FieldTypes {
    ADDRESS= 'address',
    EMAIL='email',
    INPUT = 'input',
    BINARY = 'binary',
    MULTI_CHOICE = 'multi_choice',
    SINGLE_CHOICE = 'single_choice'
}

export class QuestionModel extends Model {
    static _store: QuestionStore;

    @observable id!: number;
    @observable title!: string;
    @observable intent!: string;
    @observable organization_id!: number | null;
    @observable intent_id!: number;
    @observable type!: FieldTypes;
    @observable order!: number;
    @observable meta!: QuestionMeta;
    @observable required!: boolean;
    @observable slot!: string;
    @observable created_at!: string;
    @observable updated_at!: string;

    getBuilderComponent(): FunctionComponent<QuestionBuilderProps> {
        switch (this.type) {
            case FieldTypes.BINARY:
                return BinaryInputBuilder;
            case FieldTypes.MULTI_CHOICE:
                return ChoiceInputBuilder;
            case FieldTypes.SINGLE_CHOICE:
                return ChoiceInputBuilder;
            case FieldTypes.INPUT:
                return TextInputBuilder;
            case FieldTypes.ADDRESS:
                return TextInputBuilder;
            case FieldTypes.EMAIL:
                return TextInputBuilder;
            default:
                throw new Error('Please check the type of question');
        }
    }

    getId(): string | number {
        return this.id;
    }

    isValid(): boolean {
        if (this.title && this.slot && this.title.trim().length && this.slot.trim().length) {
            if ("multi" in this.meta) {
                return this.meta.choices.length >= 2;
            } else if ("accept_text" in this.meta) {
                return !!(this.meta.accept_text.trim().length && this.meta.reject_text.trim().length)
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
}

export type QuestionMeta = TextQuestionMeta | BinaryQuestionMeta | ChoiceQuestionMeta;

export function isTextQuestionMeta(meta: QuestionMeta): meta is TextQuestionMeta {
    return !!(meta as TextQuestionMeta).placeholder;
}

export function isBinaryQuestionMeta(meta: QuestionMeta): meta is BinaryQuestionMeta {
    return !!(meta as BinaryQuestionMeta).accept_text;
}

export function isChoiceQuestionMeta(meta: QuestionMeta): meta is ChoiceQuestionMeta {
    return !!(meta as ChoiceQuestionMeta).choices;
}

export interface TextQuestionMeta {
    placeholder: string;
}

export interface BinaryQuestionMeta {
    accept_text: string;
    reject_text: string;
}

export interface ChoiceQuestionMeta {
    multi: boolean;
    choices: string[];
}
