import React, {useEffect} from 'react';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@material-ui/core';
import {Formik} from 'formik';
import {inject, observer} from 'mobx-react';
import {RootStore} from '../../stores/RootStore';
import OrganizationsDropDown from "./OrganizationsDropdown";
import {useParams} from "react-router";
import './CreateIntentDialog.scss';

interface CreateIntentDialogProps {
    open: boolean;
    onClose: () => void;
    store: RootStore;
    isUpdate: boolean;
    isCreate: boolean;
    isCopy: boolean;
    isOrgIntent: boolean;
    title: string;
    intentId: number | null;
    intentTitle: string;
    intentDesc: string;
    intentDisplayName: string;
}

const CreateIntentDialog: React.FC<any> = (props: CreateIntentDialogProps) => {
    const intentStore = props.store.intent;
    const organizationStore = props.store.organization;
    const {organisationId} = useParams();
    const [organizationId, setOrganizationId] = React.useState(-1);
    const [baseIntent, setBaseIntent] = React.useState(false);
    useEffect(() => {
        organizationStore.load();
    });

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
            <Formik initialValues={{
                intent: props.intentTitle,
                displayName: props.intentDisplayName,
                description: props.intentDesc
            }}
                    validate={(values) => {
                        if (values.intent && values.intent.toString().trim().length && values.displayName && values.displayName.toString().trim().length) {
                            return;
                        }

                        if (!(values.intent && values.intent.toString().trim().length)) {
                            return {
                                intent: 'This field is required'
                            };
                        }
                        if (!(values.displayName && values.displayName.toString().trim().length)) {
                            return {
                                displayName: 'This field is required'
                            };
                        }
                    }}
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(true);
                        props.isUpdate && props.intentId ?
                            (intentStore.update(props.intentId, values.intent.trim(), values.displayName.trim(), values.description.trim())
                                .then(props.onClose).catch(() => {
                                    actions.setSubmitting(false);
                                }))
                            : props.isCopy && props.intentId ? (intentStore.copy(props.intentId, values.intent.trim(), values.displayName.trim(), values.description.trim(),
                            (organizationId === -1 ? null : +organizationId)).then(props.onClose).catch(() => {
                                actions.setSubmitting(false);
                            }))
                            : props.isOrgIntent ? (intentStore.create(values.intent.trim(), values.description.trim(), values.displayName.trim(), baseIntent,
                                +organisationId!).then(props.onClose)).catch(() => props.onClose())
                                : (intentStore.create(values.intent.trim(), values.description.trim(), values.displayName.trim(), baseIntent, (organizationId === -1 ? null : +organizationId))
                                    .then(props.onClose)).catch(() => {
                                    actions.setSubmitting(false)
                                });
                    }} render={(formProps: any) => {
                return (
                    <form onSubmit={formProps.handleSubmit}>
                        <DialogContent>
                            <TextField
                                autoFocus
                                label="Intent"
                                type="text"
                                fullWidth
                                value={formProps.values.intent}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                name={'intent'}
                                helperText={formProps.errors.intent && formProps.errors.intent}
                            />
                            <TextField
                                label="Display Name"
                                type="text"
                                fullWidth
                                value={formProps.values.displayName}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                name={'displayName'}
                                helperText={formProps.errors.displayName && formProps.errors.displayName}
                            />
                            <Box mt={2} style={{marginTop: "1em", marginBottom: "1em"}}>
                                <TextField
                                    name={'description'}
                                    label="Description"
                                    type="text"
                                    fullWidth
                                    variant={'outlined'}
                                    multiline={true}
                                    value={formProps.values.description}
                                    onChange={formProps.handleChange}
                                    onBlur={formProps.handleBlur}
                                />
                            </Box>
                            {
                                props.isUpdate || props.isOrgIntent ? null : (
                                    <OrganizationsDropDown
                                        organizations={organizationStore.organizations}
                                        onSelectOrganization={(id: number) => {
                                            setOrganizationId(+id);
                                        }}
                                    />)
                            }
                            {
                                props.isCreate ? <div style={{display: "flex"}}>
                                    <Checkbox
                                        edge="start"
                                        checked={baseIntent}
                                        tabIndex={-1}
                                        onChange={() => {
                                            setBaseIntent(!baseIntent)
                                        }}
                                        color={"primary"}
                                        disableRipple
                                        inputProps={{'aria-labelledby': "Required"}}
                                    />
                                    <p style={{alignSelf: "center"}}>Base Intent</p>
                                </div> : null
                            }
                            {
                                baseIntent ? <p className={'MuiFormHelperText-root'}>** Are you sure you want to create a base Intent? <br/> **This Intent will be copied to all the organisations. </p> : null
                            }
                        </DialogContent>
                        <DialogActions>
                            {formProps.isSubmitting && !(intentStore.errorMessage.length > 0)
                                ?
                                <Box display={'flex'} flexDirection={'row'}
                                     justifyContent={'center'}><CircularProgress/></Box>
                                : <>
                                    <Button color="primary" onClick={props.onClose}>
                                        Close
                                    </Button>
                                    <Button type="submit" color="primary">
                                        {props.isUpdate ? "Update" : props.isCopy ? "Copy" : "Create"}
                                    </Button>
                                </>}
                        </DialogActions>
                    </form>
                );
            }}/>
        </Dialog>
    );
};

export default inject('store')(observer(CreateIntentDialog));
