import React, {useState} from 'react';
import {
    Button,
    CardActions,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    Typography,
} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import {IntentModel} from "../../models/Intent";
import {EntityIdentifier} from "../../stores/Store";
import EditIcon from '@material-ui/icons/Edit';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import './IntentCard.scss';
import {inject, observer} from "mobx-react";

interface IntentCardProps {
    intentType?: boolean;
    intentTitle: string;
    intentDesc: string | undefined;
    intent: IntentModel;
    onDelete: (id: EntityIdentifier) => void;
    onAdd: () => void;
    onEditIntent: Function;
    onCopyIntent: Function;
    isOrgIntent?: boolean;
    organizationName?: string | undefined;
}

const IntentCard: React.FC<IntentCardProps> = (props: IntentCardProps) => {
    const [viewQuestionDialog, setViewQuestionDialog] = useState(false);
    const intent = props.intent;

    if(intent.base_intent){
        return (
            <section>
                <section className={'intent-card'}>
                    {intent.deleting || intent.isDetaching ?
                        <div className={'delete-spinner'}><CircularProgress/></div> : <> </>}
                    <section className={'cLabel'}>
                        <Typography variant={"h6"}>
                            {props.intentTitle ? props.intentTitle.length > 20 ?
                                props.intentTitle.slice(0, 20) + '...' : props.intentTitle : null}</Typography>
                        <Typography variant={"caption"}>
                            {props.intentDesc ? props.intentDesc.length > 20 ?
                                props.intentDesc.slice(0, 20) + '...' : props.intentDesc : null}
                        </Typography>
                    </section>
                    <section style={{display: "flex", justifyContent: "center"}}>
                        <div
                            className={'btn2'}
                            onClick={viewQuestionsClickHandler}>
                            View Questions
                        </div>
                    </section>
                    <section className={'info'}>
                        <div>
                            <CardActions>
                                <Tooltip title={'Copy Intent'}>
                                    <FileCopyOutlinedIcon onClick={() => {
                                        props.onCopyIntent(intent, intent.id, intent.intent, intent.description, intent.display_name)
                                    }} cursor={'pointer'}/>
                                </Tooltip>
                                <Tooltip title={props.isOrgIntent ? 'Detach Intent' : 'Delete Intent'}>
                                    <Delete cursor={'pointer'} color={'error'}
                                            onClick={() => intent.deleting || props.onDelete(intent.id)}/>
                                </Tooltip>
                            </CardActions>
                        </div>
                    </section>
                </section>
                <Dialog open={viewQuestionDialog}>
                    <DialogTitle>{"Attention!".toUpperCase()}</DialogTitle>
                    <DialogContent>Making any changes to these questions or adding or removing questions will reflect for
                        all the organization.</DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={(event => setViewQuestionDialog(false))}>
                            Close
                        </Button>
                        <Button type="submit" color="primary" onClick={(event => props.onAdd())}>
                            Proceed
                        </Button>
                    </DialogActions>
                </Dialog>
            </section>
        );
    }
    return (
        <section>
            <section className={'intent-card'}>
                {intent.deleting || intent.isDetaching ?
                    <div className={'delete-spinner'}><CircularProgress/></div> : <> </>}
                <section className={'cLabel'}>
                    <Typography variant={"h6"}>
                        {props.intentTitle ? props.intentTitle.length > 20 ?
                            props.intentTitle.slice(0, 20) + '...' : props.intentTitle : null}</Typography>
                    <Typography variant={"caption"}>
                        {props.intentDesc ? props.intentDesc.length > 20 ?
                            props.intentDesc.slice(0, 20) + '...' : props.intentDesc : null}
                    </Typography>
                </section>
                <section style={{display: "flex", justifyContent: "center"}}>
                    <div
                        className={'btn2'}
                        onClick={viewQuestionsClickHandler}>
                        View Questions
                    </div>
                </section>
                <section className={'info'}>
                    <div>
                        <CardActions>
                            <Tooltip title={'Edit Intent'}>
                                <EditIcon onClick={() => {
                                    props.onEditIntent(intent, intent.id, intent.intent, intent.description, intent.display_name)
                                }} cursor={'pointer'}/>
                            </Tooltip>
                            <Tooltip title={'Copy Intent'}>
                                <FileCopyOutlinedIcon onClick={() => {
                                    props.onCopyIntent(intent, intent.id, intent.intent, intent.description, intent.display_name)
                                }} cursor={'pointer'}/>
                            </Tooltip>
                            <Tooltip title={props.isOrgIntent ? 'Detach Intent' : 'Delete Intent'}>
                                <Delete cursor={'pointer'} color={'error'}
                                        onClick={() => intent.deleting || props.onDelete(intent.id)}/>
                            </Tooltip>
                        </CardActions>
                    </div>
                </section>
            </section>
            <Dialog open={viewQuestionDialog}>
                <DialogTitle>{"Attention!".toUpperCase()}</DialogTitle>
                <DialogContent>Making any changes to these questions or adding or removing questions will reflect for
                    all the organization.</DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={(event => setViewQuestionDialog(false))}>
                        Close
                    </Button>
                    <Button type="submit" color="primary" onClick={(event => props.onAdd())}>
                        Proceed
                    </Button>
                </DialogActions>
            </Dialog>
        </section>
    );

    function viewQuestionsClickHandler() {
        if (props.intentType) {
            setViewQuestionDialog(true);
        } else {
            props.onAdd();
        }
    }
};

export default inject('store')(observer(IntentCard));
