import Store, {EntityIdentifier} from "./Store";
import {OrganizationModel} from "../models/Organization";
import {action, computed, observable, runInAction} from "mobx";
import {IntentModel} from "../models/Intent";
import {organisationService} from '../services/OrganisationService';

export class OrganizationStore extends Store<OrganizationModel> {
    private static _instance: OrganizationStore;

    @observable isLoading: boolean = false;
    @observable isLoaded: boolean = false;
    @observable isSaving: boolean = false;
    @observable isOpen: boolean = false;
    @observable isLoadingIntents: boolean = true;
    @observable errorMessage: string = "";
    @observable currentOrganisation?: OrganizationModel;
    @observable searchString: string = "";

    private constructor() {
        super();
        OrganizationModel._store = this;
    }

    @computed
    get organizations() {
        return this.entities.filter((i: OrganizationModel) => !i.deleted);
    }


    static getInstance() {
        if (!this._instance) {
            this._instance = new OrganizationStore();
        }

        return this._instance;
    }

    @action
    setCurrentOrganisation(id: number) {
        if (!this.currentOrganisation) {
            organisationService.get(id).then(data => {
                runInAction(() => {
                    this.currentOrganisation = data;
                });
            });
        } else {
            this.currentOrganisation = this.get(id)
        }
    }

    @action
    load() {
        if (!this.isLoaded) {
            this.isLoading = true;
            organisationService.list().then(response => {
                runInAction(() => {
                    this.isLoading = false;
                    this.isLoaded = true;
                });
            }).catch(err => {
                runInAction(() => {
                    this.isSaving = false;
                    this.isLoading = false;
                    this.isOpen = true;
                    if (err.error.errorCode === 601 || err.error.errorCode === 602) {
                        this.errorMessage = err.message;
                    } else {
                        this.errorMessage = "Some Problem Occurred Unable to load Organisations";
                    }
                });
                throw err;
            });
        }
    }

    @action
    async create(
        name: string,
        logo: FormData | undefined,
        description: string,
        phone_number:number,
        theme: string | undefined,
        messageIconData: FormData | undefined,
        crossButtonIconData: FormData | undefined,
        enable_message_button: boolean
    ) {
        this.isSaving = true;
        try {
            const organization = await organisationService.create({name, phone_number, description, theme, enable_message_button});
            if (logo){
                await organisationService.uploadImage(organization.id, logo);
            }
            if (messageIconData) {
                await organisationService.uploadMessageIcon(organization.id, messageIconData);
            }
            if (crossButtonIconData) {
                await organisationService.uploadCrossButtonIcon(organization.id, crossButtonIconData);
            }
            this.isSaving = false;

        } catch (err) {
            runInAction(() => {
                this.isSaving = false;
                this.isLoading = false;
                this.isOpen = true;
                if (err.error.errorCode === 601 || err.error.errorCode === 602) {
                    this.errorMessage = err.message;
                } else {
                    this.errorMessage = "Some Problem Occurred while creating Organisation";
                }
            });
            setTimeout(() => {
                this.closeSnackBar();
            }, 3000);

            throw err;
        }

        // this.isSaving = true;
        // await organisationService.create({name, logo, description}).then(organisation => {
        //     runInAction(() => {
        //         this.isSaving = false;
        //     });
        // }).catch(err => {
        //     runInAction(() => {
        //         this.isSaving = false;
        //         this.isLoading = false;
        //         this.isOpen = true;
        //         if (err.error.errorCode === 601 || err.error.errorCode === 602) {
        //             this.errorMessage = err.message;
        //         } else {
        //             this.errorMessage = "Some Problem Occurred while creating Organisation";
        //         }
        //     });
        //     setTimeout(() => {
        //         this.closeSnackBar();
        //     }, 3000);
        //
        //     throw err;
        // });
    }

    @action
    async update(organizationId: number,
                 name: string,
                 logo: FormData | undefined,
                 description: string,
                 phone_number:number,
                 theme: string | undefined,
                 messageIconData: FormData | undefined,
                 crossButtonIconData: FormData | undefined,
                 enable_message_button: boolean
    ) {
        this.isSaving = true;
        try {
            await organisationService.update(organizationId, {name, phone_number, description, theme, enable_message_button});
            if (logo) {
                await organisationService.uploadImage(organizationId, logo);
            }
            if (messageIconData) {
                await organisationService.uploadMessageIcon(organizationId, messageIconData);
            }
            if (crossButtonIconData) {
                await organisationService.uploadCrossButtonIcon(organizationId, crossButtonIconData);
            }
            this.isSaving = false;
        } catch (err) {
            runInAction(() => {
                this.isSaving = false;
                this.isLoading = false;
                this.isOpen = true;
                if (err.error.errorCode === 601 || err.error.errorCode === 602) {
                    this.errorMessage = err.message;
                } else {
                    this.errorMessage = "Some Problem Occurred while updating Organisation";
                }
            });

            setTimeout(() => {
                this.closeSnackBar();
            }, 3000);

            throw err;
        }

        this.isSaving = true;

        // return organisationService.update(organizationId, {name, logo, description}).then(organisation => {
        //     runInAction(() => {
        //         this.isSaving = false;
        //     });
        // }).catch(err => {
        //     runInAction(() => {
        //         this.isSaving = false;
        //         this.isLoading = false;
        //         this.isOpen = true;
        //         if (err.error.errorCode === 601 || err.error.errorCode === 602) {
        //             this.errorMessage = err.message;
        //         } else {
        //             this.errorMessage = "Some Problem Occurred while updating Organisation";
        //         }
        //     });
        //
        //     setTimeout(() => {
        //         this.closeSnackBar();
        //     }, 3000);
        //
        //     throw err;
        // });
    }

    @action
    async updateEnableMessageButtonState(organizationId: number, enable_message_button: boolean) {
        this.isSaving = true;
        try {
            await organisationService.updateEnableMessageButtonState(organizationId, {enable_message_button});
            this.isSaving = false;
        } catch (err) {
            runInAction(() => {
                this.isSaving = false;
                this.isLoading = false;
                this.isOpen = true;
                if (err.error.errorCode === 601 || err.error.errorCode === 602) {
                    this.errorMessage = err.message;
                } else {
                    this.errorMessage = "Failed to update Organisation Enable Message Button State";
                }
            });

            setTimeout(() => {
                this.closeSnackBar();
            }, 3000);

            throw err;
        }
    }

    @action
    attachIntent(intent: IntentModel, organization_id: number) {
        this.isSaving = true;

        let body: any = {intent_id: intent.id, organization_id};

        return organisationService.attachIntent(body).then(response => {
            runInAction(() => {
                this.isSaving = false;
                const org = this.get(organization_id) as OrganizationModel;
                if (!org.intents) {
                    org.intents = [];
                }
                const isIntentPresent = org.intents.find((intentItem) => intentItem.id === intent.id);
                if (!isIntentPresent) {
                    org.intents.push(intent as IntentModel);
                }
            });
        }).catch(err => {
            runInAction(() => {
                this.isSaving = false;
                this.isLoading = false;
                this.isOpen = true;
                this.errorMessage = err.message;
            });

            throw err;
        });
    }

    @action
    detachIntent(intent: IntentModel, organization_id: number) {
        this.isSaving = true;
        intent.isDetaching = true;

        let body: any = {intent_id: intent.id, organization_id};

        return organisationService.detachIntent(body).then(response => {
            runInAction(() => {
                this.isSaving = false;
                intent.isDetaching = false;
                const org = this.get(organization_id) as OrganizationModel;
                if (org.intents) {
                    org.intents = org.intents.filter((intentItem) => intentItem.id !== intent.id);
                }
            });
        }).catch(err => {
            runInAction(() => {
                this.isSaving = false;
                intent.isDetaching = false;
                this.isLoading = false;
                this.isOpen = true;
                this.errorMessage = err.message;
            });

            throw err;
        });
    }

    @action
    delete(id: EntityIdentifier) {
        const organization = this.get(id)!;
        organization.deleting = true;

        return organisationService.delete(id).then(resp => {
            runInAction(() => {
                organization.deleting = false;
                organization.deleted = true;
            })
        }).catch(err => {
            runInAction(() => {
                organization.deleting = false;
                this.isOpen = true;
                if (err.error.errorCode === 201 || err.error.errorCode === 202) {
                    this.errorMessage = err.message;
                } else {
                    this.errorMessage = "Some Problem Occurred while deleting Organisation";
                }
            });
            throw err;
        });
    }

    @action
    closeSnackBar = () => {
        runInAction(() => {
            this.isOpen = false;
            this.errorMessage = "";
        });
    };

    @action
    updateSearchString = (s: string) => {
        this.searchString = s;
    }

}
