import React from 'react';
import {Box, Button, TextField} from '@material-ui/core';
import {QuestionBuilderProps} from './TextInputBuilder';
import {BinaryQuestionMeta, QuestionMeta} from '../../../models/Question';
import {observer} from 'mobx-react';

const BinaryInputBuilder: React.FC<QuestionBuilderProps> = (props: QuestionBuilderProps) => {
    const meta = _getMeta();

    return <Box display={'flex'}>
        <Box m={2}>
            <Button style={{backgroundColor: 'rgba(255,255,255,255)', width: '200px'}} variant={'contained'}
                    disableRipple>
                <TextField
                    multiline
                    onChange={(e) => {
                        meta.accept_text = e.target.value;
                    }}
                    onBlur={() => _onBlur(meta)}
                    value={meta.accept_text}/>
            </Button>
        </Box>
        <Box m={2}>
            <Button style={{background: 'rgba(255,255,255,255)', width: '200px'}} variant={'contained'} disableRipple>
                <TextField
                    multiline
                    onChange={(e) => {
                        meta.reject_text = e.target.value;
                    }}
                    onBlur={() => _onBlur(meta)}
                    value={meta.reject_text}/>
            </Button>

        </Box>
    </Box>;

    function _getMeta(): BinaryQuestionMeta {
        return props.meta as BinaryQuestionMeta;
    }

    function _onBlur(meta: QuestionMeta) {
        props.onChange(meta);
    }
};

export default observer(BinaryInputBuilder);
