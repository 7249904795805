import React from 'react';
import { SketchPicker } from 'react-color'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Switch from '@material-ui/core/Switch';
import {Formik} from 'formik';
import {inject, observer} from 'mobx-react';
import {RootStore} from '../../stores/RootStore';
import './CreateOrganizationDialog.scss';


interface CreateOrganizationDialogProps {
    onClose: () => void;
    store: RootStore;
    isUpdate: boolean;
    title: string;
    organizationId: number | null;
    organizationName: string;
    organizationDesc: string;
    organizationTheme: string | undefined;
    organizationPhoneNumber:number;
    organizationLogoUrl: string;
    organizationEnableMessageButton: boolean;
}

const CreateOrganizationDialog: React.FC<any> = (props: CreateOrganizationDialogProps) => {
    const organizationStore = props.store.organization;
    const [imgPreview, setImgPreview] = React.useState<any>(<Avatar className={"logo"}>O</Avatar>);
    const [logoData, setLogoData] = React.useState<FormData>();
    const [messageIconData, setMessageIconData] = React.useState<FormData>();
    const [crossButtonIconData, setCrossButtonIconData] = React.useState<FormData>();
    const fileUrl = React.useRef('');
    const [theme, setTheme] = React.useState(props.organizationTheme);
    const [openThemeDialog, setOpenThemeDialog] = React.useState(false);
    const [enableMessageButton, setEnableMessageButton] = React.useState(props.organizationEnableMessageButton);

    function _upload(e: any) {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('organization_image', file, file.name);
            setLogoData(formData);
        }
        reader.onloadend = () => {
            fileUrl.current = file;
            setImgPreview(reader.result ? (<Avatar alt="Remy Sharp" src={reader.result.toString()} className={"logo"}/>)
                :
                (<Avatar className={"logo"}>H</Avatar>))
        };

        reader.readAsDataURL(file);

    }

    function _uploadMessageIcon(e: any) {
        e.preventDefault();

        let file = e.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('message_icon', file, file.name);
            setMessageIconData(formData);
        }
    }

    function _uploadCrossButtonIcon(e: any) {
        e.preventDefault();

        let file = e.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('cross_button_icon', file, file.name);
            setCrossButtonIconData(formData);
        }
    }

    const handleChangeComplete = (color:any) => {
        setTheme(color.hex);
    };

    return (
        <Dialog open={true} onClose={props.onClose} aria-labelledby="form-dialog-title" className={"dialog_box"}>
            <DialogTitle className={'title'} id="form-dialog-title">{props.title}</DialogTitle>
            {props.organizationLogoUrl ? <Avatar className={"logo"} src={props.organizationLogoUrl}/> : imgPreview}
            <Formik initialValues={{
                name: props.organizationName,
                logo: props.organizationLogoUrl,
                description: props.organizationDesc,
                phoneNumber:props.organizationPhoneNumber
            }} validate={(values) => {
                if (values.name && values.name.toString().trim().length && values.phoneNumber && values.phoneNumber.toString().trim().length) {
                    return;
                }
                if (!(values.name && values.name.toString().trim().length)) {
                    return {
                        name: 'This field is required'
                    };
                }
                if (!(values.phoneNumber && values.phoneNumber.toString().trim().length)) {
                    return {
                        phoneNumber: 'This field is required'
                    };
                }
            }} onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                props.isUpdate ?
                    (organizationStore.update(
                        props.organizationId!,
                        values.name.trim(),
                        logoData,
                        values.description.trim(),
                        values.phoneNumber,
                        theme,
                        messageIconData,
                        crossButtonIconData,
                        enableMessageButton
                        )
                        .then(() => {
                            props.onClose();
                            setLogoData(undefined);
                            setMessageIconData(undefined)
                            setCrossButtonIconData(undefined)
                            setImgPreview(<Avatar className={"logo"}>O</Avatar>);
                        }).catch(() => {actions.setSubmitting(false);
                            setLogoData(undefined);
                            setMessageIconData(undefined)
                            setCrossButtonIconData(undefined)
                            setImgPreview(<Avatar className={"logo"}>O</Avatar>);})) :
                    (organizationStore.create(
                        values.name.trim(),
                        logoData,
                        values.description.trim(),
                        values.phoneNumber,
                        theme,
                        messageIconData,
                        crossButtonIconData,
                        enableMessageButton
                    )
                        .then(() => {
                            props.onClose();
                            setLogoData(undefined);
                            setMessageIconData(undefined)
                            setCrossButtonIconData(undefined)
                            setImgPreview(<Avatar className={"logo"}>O</Avatar>);
                        }).catch(() => {actions.setSubmitting(false);
                            setLogoData(undefined);
                            setMessageIconData(undefined)
                            setCrossButtonIconData(undefined)
                            setImgPreview(<Avatar className={"logo"}>O</Avatar>);}))
            }} render={(formProps) => {
                return (
                    <form onSubmit={formProps.handleSubmit}>
                        <DialogContent>
                            <TextField
                                autoFocus
                                label="Organisation Name"
                                type="text"
                                fullWidth
                                value={formProps.values.name}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                name={'name'}
                                helperText={formProps.errors.name && formProps.errors.name}
                            />
                            <TextField
                                label="Phone Number"
                                type="text"
                                fullWidth
                                value={formProps.values.phoneNumber}
                                onChange={formProps.handleChange}
                                onBlur={formProps.handleBlur}
                                name={'phoneNumber'}
                                helperText={formProps.errors.phoneNumber && formProps.errors.phoneNumber}
                                style={{marginBottom: "1em"}}
                            />
                            <br/>
                            <div className={'upload_root'}>
                                <b>Upload Organisation Logo</b>
                                <span className={'choose_file'}>
                                    <input type='file' accept='image/*' onChange={(e) => {
                                        _upload(e);
                                    }}/>
                                </span>
                            </div>

                            <div className={'upload_message_icon'}>
                                <b>Upload Message Icon</b>
                                <span className={'choose_file'}>
                                    <input type='file' accept='image/*' onChange={(e) => {
                                        _uploadMessageIcon(e);
                                    }}/>
                                </span>
                            </div>

                            <div className={'upload_message_icon'}>
                                <b>Upload Cross Button Icon</b>
                                <span className={'choose_file'}>
                                    <input type='file' accept='image/*' onChange={(e) => {
                                        _uploadCrossButtonIcon(e);
                                    }}/>
                                </span>
                            </div>

                            <Box mt={2} style={{marginTop: "1em", marginBottom: "1em"}}>
                                <TextField
                                    name={'description'}
                                    label="Description"
                                    type="text"
                                    fullWidth
                                    variant={'outlined'}
                                    multiline={true}
                                    value={formProps.values.description}
                                    onChange={formProps.handleChange}
                                    onBlur={formProps.handleBlur}
                                />
                            </Box>
                        </DialogContent>
                        <div className={'enableMessageButtonSwitch'}>
                            <b className={'switchLabel'}>Enable Message Button:</b>
                            <Switch
                                checked={enableMessageButton}
                                onChange={() => {
                                    setEnableMessageButton(!enableMessageButton)
                                }}
                                color="primary"
                                name="checkedB"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                        <div className={'themeColorContainer'}>
                            <b>App Theme:</b>
                            <div className={'themeColorField'} onClick={() => {
                                setOpenThemeDialog(true);
                            }}>
                                <div className={'themeColor'} style={{background: theme ? theme : (
                                    props.organizationTheme ? props.organizationTheme : "#48c3e3"
                                    )}}/>
                                <span>{theme ? theme : (props.organizationTheme ? props.organizationTheme : "#48c3e3")}</span>
                            </div>
                        </div>
                        <Dialog open={openThemeDialog} onClose={() => {
                            setOpenThemeDialog(false);
                        }}>
                                <SketchPicker
                                    width="380px"
                                    color={ theme }
                                    onChangeComplete={ handleChangeComplete }
                                />
                        </Dialog>
                        <DialogActions>
                            {formProps.isSubmitting
                                ?
                                <Box display={'flex'} flexDirection={'row'}
                                     justifyContent={'center'}><CircularProgress/></Box>
                                : <>
                                    <Button color="primary" onClick={() => {
                                        props.onClose();
                                        setLogoData(undefined);
                                        setMessageIconData(undefined);
                                        setCrossButtonIconData(undefined);
                                        setImgPreview(<Avatar className={"logo"}>O</Avatar>)
                                    }}>
                                        Close
                                    </Button>
                                    <Button type="submit" color="primary">
                                        {props.isUpdate ? "Update" : "Create"}
                                    </Button>
                                </>}
                        </DialogActions>
                    </form>
                );
            }}/>
        </Dialog>
    );
};

export default inject('store')(observer(CreateOrganizationDialog));
