import React from 'react';
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import {IntentModel} from "../../models/Intent";

interface IntentDropDownProps {
    intents: IntentModel[];
    onSelectIntent: Function;
}

const IntentDropDown: React.FC<IntentDropDownProps> = (props: IntentDropDownProps) => {
    const [intentArray] = React.useState([...props.intents]);
    const [selectedIntentName, setSelectedIntentName] = React.useState("");

    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, intent: IntentModel) => {
        props.onSelectIntent(intent);
        setSelectedIntentName(intent.intent);
    };

    return (
        <Autocomplete
            options={intentArray}
            getOptionLabel={(intent: any) => intent.intent}
            autoHighlight
            renderOption={(intent: any) =>
                <span style={{width: "100%"}}
                      key={intent.id}
                      onClick={(event) => {
                          handleMenuItemClick(event, intent)
                      }}>{intent.intent}</span>}
            onInputChange={(event, value) => {
                setSelectedIntentName(value);
            }}
            onChange={(event, intent) => {
                intent ? setSelectedIntentName(intent.intent) : console.log("None");
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Select Intent"
                    placeholder="Select Intent"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{...params.InputLabelProps, shrink: true}}
                    InputProps={{
                        ...params.InputProps,
                    }}
                    inputProps={{
                        ...params.inputProps,
                        value: selectedIntentName,
                    }}
                />
            )}/>

    );
};

export default IntentDropDown;
