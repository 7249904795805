import React from 'react';
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";

interface OrganizationsDropdownProps {
    organizations: any[];
    onSelectOrganization: Function;
}


const OrganizationsDropDown: React.FC<OrganizationsDropdownProps> = (props: OrganizationsDropdownProps) => {
    const noneorganization = {
        id: -1,
        name: "none"
    };

    const [organizationsArray] = React.useState([noneorganization, ...props.organizations]);
    const [selectedOrganizationName, setSelectedOrganizationName] = React.useState("");


    const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, organizationId: number, organizationName: string) => {
        props.onSelectOrganization(organizationId);
        setSelectedOrganizationName(organizationName);
    };

    return (
        <Autocomplete
            // id="organization-dropdown"
            options={organizationsArray}
            getOptionLabel={(organization: any) => organization.name}
            autoHighlight
            renderOption={(organization: any) => (
                <span
                    style={{width: "100%"}}
                    key={organization.id}
                    onClick={(event) => {
                        handleMenuItemClick(event, organization.id, organization.name)
                    }}
                >
                    {organization.name}
                </span>
            )}
            onInputChange={(event, value) => {
                setSelectedOrganizationName(value);
            }}
            onChange={(event, organization) => {
                organization ? setSelectedOrganizationName(organization.name) : console.log("None");
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Select organization"
                    placeholder="Select organization"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{...params.InputLabelProps, shrink: true}}
                    InputProps={{
                        ...params.InputProps,
                    }}
                    inputProps={{
                        ...params.inputProps,
                        value: selectedOrganizationName,
                        // onChange: (event: any) => {
                        //     setSelectedSlotName(event.target.value)
                        // }
                    }}
                />
            )}
        />
    );
};

export default OrganizationsDropDown;
