import React, {useEffect} from 'react'
import {RouteComponentProps} from "react-router";
import {CircularProgress, Icon, IconButton, Snackbar, SnackbarContent} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {Close} from "@material-ui/icons";
import {OrganizationStore} from "../../stores/OrganizationStore";
import {OrganizationModel} from "../../models/Organization";
import OrganizationCard from "../OrganizationCard/OrganizationCard";
import CreateOrganizationDialog from "../CreateOrganizationDialog/CreateOrganizationDialog";
import './Organisation.scss';
import Typography from "@material-ui/core/Typography";
import SearchBar from "../customs/search-bar/SearchBar";
import {EntityIdentifier} from "../../stores/Store";
import {organisationService} from "../../services/OrganisationService";
import {runInAction} from "mobx";


const Organization: React.FC<RouteComponentProps<{}>> = (props: any) => {
    const organizationStore = props.store.organization as OrganizationStore;
    const organisations = organizationStore.organizations;

    const [openCreateOrganizationDialog, setOpenCreateOrganizationDialog] = React.useState<boolean>(false);
    const [copied, setCopied] = React.useState<boolean>(false);
    const [copyError, setcopyError] = React.useState<boolean>(false);
    const [updatingOrganizationId, setUpdatingOrganizationId] = React.useState<number | null>(null);
    useEffect(() => {
        runInAction((() => {
            organizationStore.searchString = '';
        }))
        organizationStore.load();
    });

    return (
        <div className={'organisation'}>
            <div className={'header'}>
                <div className={'heading'}>
                    <Typography variant={"h4"} style={{color: '#2BA5D4'}}>Organisations</Typography>
                    <div
                        className={'add-btn'}
                        onClick={() => setOpenCreateOrganizationDialog(true)}>
                        + Add Organisation
                    </div>
                </div>
                <SearchBar onChange={(query) => _handleSearch(query)}/>
            </div>
            <div className={'organisation-cards'}>
                {organizationStore.isLoading && <CircularProgress className={'abs-center'}/>}
                {organizationStore.searchString.length === 0 ?
                    organisations.length ? organisations.map(showOrganization) : organizationStore.isLoading ? null :
                        <Typography variant={"h5"} style={{color: '#2BA5D4'}} className={'no-org'}>No
                            Organisation</Typography>
                    : organisations.filter((org) =>
                        org.name.toUpperCase().includes(organizationStore.searchString
                            .toUpperCase())).length ? organisations.filter((org) =>
                            org.name.toUpperCase().includes(organizationStore.searchString
                                .toUpperCase())).map(showOrganization) :
                        <Typography variant={"h5"} style={{color: '#2BA5D4'}} className={'no-org'}>No
                            Organisation</Typography>}
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={organizationStore.isOpen}
                autoHideDuration={6000}
            >
                <SnackbarContent
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar">
                            <Icon/>
                            {organizationStore.errorMessage}
                        </span>
                    }
                    action={[
                        <IconButton key="close" aria-label="close" color="inherit"
                                    onClick={organizationStore.closeSnackBar}>
                            <Close/>
                        </IconButton>,
                    ]}
                />
            </Snackbar>
            <Snackbar open={copied || copyError} autoHideDuration={3000} onClose={() => {
                setCopied(false);
                setcopyError(false);
            }}>
                <SnackbarContent
                    message={
                        copyError ?
                            <span>
                            <Icon/>
                            Failed to copy
                        </span> : <span>
                            <Icon/>
                            Script Successfully Copied
                        </span>
                    }
                    action={[
                        <IconButton key="close" aria-label="close" color="inherit"
                                    onClick={() => {
                                        setCopied(false)
                                    }}>
                            <Close/>
                        </IconButton>,
                    ]}
                />
            </Snackbar>
            {
                openCreateOrganizationDialog && <CreateOrganizationDialog
                    onClose={() => {
                        setOpenCreateOrganizationDialog(false);
                    }}
                    title={"Create Organisation"}
                    isUpdate={false}
                    organizationId={null}
                    organizationName={''}
                    organizationDesc={''}
                    organizationTheme={''}
                    organizationLogoUrl={''}
                    organizationPhoneNumber={null}
                    organizationEnableMessageButton={true}
                />
            }
        </div>
    );

    function GetWidgetCode(id: EntityIdentifier) {
        organisationService.getWidgetScript(id).then((data: any) => {
            data = data.replace(/\n/g, "n");
            document.execCommand("copy");
            navigator.clipboard.writeText(data).then((data) => {
                setCopied(true);
            }).catch((err) => {
                setcopyError(false);
                setCopied(true)
            });
        }).catch(err => {
            setcopyError(true);
            setCopied(true)
        });
    }

    function GetTextWidgetCode(id: EntityIdentifier) {
        organisationService.getTextWidgetScript(id).then((data: any) => {
            data = data.replace(/\n/g, "n");
            document.execCommand("copy");
            navigator.clipboard.writeText(data).then((data) => {
                setCopied(true);
            }).catch((err) => {
                setcopyError(false);
                setCopied(true)
            });
        }).catch(err => {
            setcopyError(true);
            setCopied(true)
        });
    }

    function handleSwitchEnableMessageButton(organizationId: number, enableMessageButton: boolean) {
        setUpdatingOrganizationId(organizationId)
        organizationStore.updateEnableMessageButtonState(
            organizationId,
            enableMessageButton
        )
    }

    function showOrganization(organization: OrganizationModel, index: number) {
        return <OrganizationCard
            key={organization.id}
            organizationTitle={organization.name}
            organizationLogo={organization.logo}
            organizationDesc={organization.description}
            organizationTheme={organization.theme}
            onDelete={organizationStore.delete.bind(organizationStore)}
            onAdd={() => _setOrganisation(organization.id)}
            organization={organization}
            onGetWidgetCode={GetWidgetCode}
            onGetTextWidgetCode={GetTextWidgetCode}
            onToggleEnableMessageButton={handleSwitchEnableMessageButton}
            updatingOrganizationId={updatingOrganizationId}
            organizationIsSaving={organizationStore.isSaving}
        />
    }

    function _setOrganisation(id: number) {
        organizationStore.setCurrentOrganisation(id);
        props.history.push(`/organization/${id}`)
    }

    function _handleSearch(query: string) {
        organizationStore.updateSearchString(query);
    }
};

export default inject('store')(observer(Organization));
