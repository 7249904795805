import {AUTH_TOKEN} from '../services/ApiService';
import {Redirect, Route} from 'react-router';
import React from 'react';

const PublicRoute: React.FC<any> = ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={(props) => (
            !localStorage.getItem(AUTH_TOKEN) ?
                <Component {...props} /> : <Redirect to={{pathname: '/', state: {from: props.location}}}/>
        )}/>
    );
};

export default PublicRoute;
