import React, {ChangeEvent, KeyboardEvent} from 'react';
import {Box, Chip, createStyles, makeStyles, TextField, Theme} from '@material-ui/core';
import {QuestionBuilderProps} from './TextInputBuilder';
import {ChoiceQuestionMeta} from '../../../models/Question';
import {observer} from 'mobx-react';

const useStyles = makeStyles((theme: Theme) => createStyles({
    responses: {
        color: 'grey',
    }
}));

const ChoiceInputBuilder: React.FC<QuestionBuilderProps> = (props: QuestionBuilderProps) => {
    const classes = useStyles();
    const choices = _getMeta().choices;
    const [currentValue, setCurrentValue] = React.useState<string>('');

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <TextField
                helperText={'* At least two options are required for this field'}
                multiline
                value={currentValue}
                onKeyDown={_addChoice}
                onChange={_onChange}
                placeholder={'Type your option'}/>
            {choices.length ? <p className={classes.responses}>RESPONSES</p> : <></>}
            <div style={{display: "flex", flexWrap: "wrap"}}>
                <Box display={'flex'} style={{flexWrap: "wrap"}}>{choices.map((choice, index) =>
                    <Box display={'flex'}
                         key={`${choice}${index}`}
                         m={2}><Chip
                        color="primary"
                        label={choice}
                        onDelete={() => _handleDelete(choice)}/></Box>)}</Box>
            </div>
        </Box>
    );

    function _onChange(e: ChangeEvent<HTMLInputElement>) {
        setCurrentValue(e.target.value);
    }

    function _handleDelete(choice: string) {
        const meta = _getMeta();
        meta.choices.splice(meta.choices.findIndex(c => c === choice), 1);
        props.onChange(meta);

        console.log(meta);
    }

    function _addChoice(event: KeyboardEvent<HTMLInputElement>) {
        if (event.keyCode === 13 && currentValue) {
            const meta = _getMeta();
            if(currentValue.trim().length) {
                choices.push(currentValue);
                props.onChange(meta);
            }

            setCurrentValue('');
        }
    }

    function _getMeta(): ChoiceQuestionMeta {
        return props.meta as ChoiceQuestionMeta;
    }
};

export default observer(ChoiceInputBuilder);
