import {action, observable, runInAction} from 'mobx';
import {UserModel} from '../models/User';
import Store from './Store';
import {apiService, AUTH_TOKEN} from '../services/ApiService';
import {LoginResponse} from '../models/responses/LoginResponse';

export class UserStore extends Store<UserModel> {
    private static _instance: UserStore;
    @observable isLoggedIn: boolean = false;
    @observable isLoggingIn: boolean = false;
    @observable profile: UserModel | undefined;

    private constructor() {
        super();
        UserModel._store = this;
    }

    static getInstance(): UserStore {
        if (!this._instance) {
            this._instance = new UserStore();
        }

        return this._instance;
    }

    // Any methods in stores/models starting with load:
    // 1. denote GET request our APIs, ret are synchronous with
    // 2. Trigger asynchronous operations but are synchronous themselves
    // 3. return void
    // 4. In contrast stores/model methods doing POST/PUT/PATCH are async and return promise of corresponding Model/Model[]

    // 5. stores/model methods doing DELETE are also async and return promise of void
    @action
    login(data: { email: string, password: string }) {
        this.isLoggingIn = true;

        return apiService.post<LoginResponse>('/admin/login', data).then(response => {
            const token = 'Bearer ' + response.token;
            localStorage.setItem(AUTH_TOKEN, token);
            runInAction(() => {
                this.isLoggingIn = false;
                this.isLoggedIn = true;
                this.profile = UserModel.fromJson(response.admin) as UserModel;
            });
        }).catch(err => {
            runInAction(() => {
                this.isLoggingIn = false;
                this.isLoggedIn = false;
            });

            throw err;
        });
    }
}
