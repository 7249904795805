import {apiService} from "./ApiService";
import {FieldTypes, QuestionMeta, QuestionModel} from "../models/Question";
import {EntityIdentifier} from "../stores/Store";

class QuestionService {
    static getInstance(): QuestionService {
        return new QuestionService()
    }

    add(data: {
        intent_id: EntityIdentifier,
        question: string,
        meta: QuestionMeta,
        type: FieldTypes
    }): Promise<QuestionModel> {
        return apiService.post<{ data: any }>('/questions', data).then(res => QuestionModel.fromJson(res.data) as QuestionModel)
    }

    update(id: EntityIdentifier, data: {
        question: string,
        required: boolean,
        slot: string,
        meta: QuestionMeta
    }) : Promise<QuestionModel> {
        return apiService.put<{data : any}>(`/questions/${id}`, data).then(res => QuestionModel.fromJson(res.data) as QuestionModel)
    }

    delete(id:EntityIdentifier): Promise<void> {
        return apiService.delete(`/questions/${id}`)
    }

    swap(idFrom:EntityIdentifier,fromOrder:number,idTo:EntityIdentifier,toOrder:number): Promise<void>{
        return apiService.put('/questions-swap',{
            "orders": [
                {
                    "question_id": idFrom,
                    "order": toOrder
                },
                {
                    "question_id": idTo,
                    "order": fromOrder
                }
            ]
        });
    }
}

export const questionService = QuestionService.getInstance();