import * as Axios from 'axios';

export interface RequestOptions {
    useAuthHeaders?: boolean;
    useBaseUrl?: boolean;
    headers?: any;
}

const BASE_URL = process.env.REACT_APP_BACKEND_URL!;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                export const AUTH_TOKEN = 'auth_token';

class ApiService {
    private constructor() {}

    static getInstance(): ApiService {
        return new ApiService();
    }

    get<T>(url: string, params?: any, config?: RequestOptions): Promise<T> {
        return Axios.default.get(this._getUrl(url, config), {
            params: params || null,
            headers: this._getHeaders(config)
        }).then(response => response.data).catch(err => {
            throw err.response.data;
        });
    }

    async post<T>(url: string, body: any, config?: RequestOptions): Promise<T> {
        return Axios.default.post(this._getUrl(url, config), body, {
            headers: this._getHeaders(config)
        }).then(response => response.data).catch(err => {
            throw err.response.data;
        });
    }

    async put<T>(url: string, body: any, config?: RequestOptions): Promise<T> {
        return Axios.default.put(this._getUrl(url, config), body, {
            headers: this._getHeaders(config)
        }).then(response => response.data).catch(err => {
            throw err.response.data;
        });
    }

    delete<T>(url: string, body?: any, config?: RequestOptions): Promise<T> {
        return Axios.default.delete(this._getUrl(url, config), {
            headers: this._getHeaders(config),
            data: body
        }).then(response => response.data).catch(err => {
            throw err.response.data
        });
    }

    private _getUrl(relativePath: string, config?: RequestOptions) {
        if (config && config.useBaseUrl === false) {
            return BASE_URL;
        }

        return BASE_URL + relativePath;
    }

    private _getHeaders(config?: RequestOptions) {
        if (config && config.useAuthHeaders === false) {
            return config.headers;
        }

        return {
            ...(config || {}).headers,
            'Authorization': localStorage.getItem(AUTH_TOKEN)
        };
    }
}

export const apiService = ApiService.getInstance();
