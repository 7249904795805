import {computed, observable} from 'mobx';
import {QuestionStore} from './QuestionStore';
import {UserStore} from './UserStore';
import {IntentStore} from './IntentStore';
import {OrganizationStore} from "./OrganizationStore";
import {IntentModel} from '../models/Intent';

export class RootStore {
    private static _instance: RootStore;

    @observable user: UserStore;
    @observable question: QuestionStore;
    @observable intent: IntentStore;
    @observable organization: OrganizationStore;

    private constructor() {
        this.user = UserStore.getInstance();
        this.question = QuestionStore.getInstance();
        this.intent = IntentStore.getInstance();
        this.organization = OrganizationStore.getInstance();
    }

    static getInstance(): RootStore {
        if (!this._instance) {
            this._instance = new RootStore();
        }

        return this._instance
    }

    @computed get currentOrganisationIntents() {
        if (this.organization.currentOrganisation && this.organization.currentOrganisation.intents) {
            const orgIntentIds = this.organization.currentOrganisation.intents.map((intent: IntentModel) => intent.id);

            return this.intent.intents.filter((intent: IntentModel) => !orgIntentIds.includes(intent.id));
        } else {
            return this.intent.intents;
        }
    }
}
