import React, {useEffect} from 'react';

import './AttachIntent.scss'
import {Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import IntentDropDown from "./IntentDropDown";
import {IntentStore} from "../../stores/IntentStore";
import {inject, observer} from "mobx-react";
import {RootStore} from "../../stores/RootStore";
import CreateIntentDialog from "../CreateIntentDialog/CreateIntentDialog";
import {useParams} from "react-router";
import {IntentModel} from "../../models/Intent";
import {OrganizationStore} from "../../stores/OrganizationStore";


interface AttachIntentDialogProps {
    open: boolean;
    onClose: () => void;
    availableIntents: IntentModel[];
    store?: RootStore;
}

const AttachIntentDialog: React.FC<AttachIntentDialogProps> = (props: AttachIntentDialogProps) => {
    const intentStore = props.store!.intent as IntentStore;
    const organisationStore = props.store!.organization as OrganizationStore;
    const {organisationId} = useParams();
    const [openCreateIntentDialog, setOpenCreateIntentDialog] = React.useState<boolean>(false);
    const [intent, setIntent] = React.useState();
    useEffect(() => {
        // organisationStore.setCurrentOrganisation(+organisationId!);
        intentStore.load();
    });

    return (
        <>
            <Dialog open={props.open} onClose={props.onClose}>
                <DialogTitle id="form-dialog-title">Attach Intent</DialogTitle>
                <DialogContent className='attach-content'>
                    <Button className='new-intent-btn' onClick={() => _handleDialogs()}>Create New Intent</Button>
                    <p>or</p>
                    <IntentDropDown onSelectIntent={(intent: IntentModel) => setIntent(intent)}
                                    intents={props.availableIntents}/>
                </DialogContent>
                <DialogActions>
                    {organisationStore.isSaving ? <CircularProgress/> :
                        <>
                            <Button color="primary" onClick={props.onClose}>
                                Close
                            </Button>
                            <Button type="submit" color="primary" onClick={() => _attachIntent()}>
                                Attach
                            </Button>
                        </>
                    }

                </DialogActions>
            </Dialog>
            <CreateIntentDialog open={openCreateIntentDialog}
                                onClose={() => setOpenCreateIntentDialog(false)}
                                title={'Create Intent'}
                                isOrgIntent={true}
                                intentId={null} intentTitle={''} intentDesc={''}/>
        </>
    );

    function _handleDialogs() {
        props.onClose();
        setOpenCreateIntentDialog(true);
    }

    function _attachIntent() {
        organisationStore.attachIntent(intent, +organisationId!).then(props.onClose);
    }

};

export default inject('store')(observer(AttachIntentDialog));
