import Model from './Model';
import {UserStore} from '../stores/UserStore';
import {observable} from 'mobx';

export class UserModel extends Model {
    static _store: UserStore;

    @observable email!: string;

    getId(): string | number {
        return this.email;
    }
}
