import {OrganizationModel} from '../models/Organization';
import {apiService} from './ApiService';
import {EntityIdentifier} from "../stores/Store";

class OrganisationService {
    static getInstance(): OrganisationService {
        return new OrganisationService()
    }

    get(id: EntityIdentifier): Promise<OrganizationModel> {
        return apiService.get<{ data: any }>(`/organizations/${id}`).then(res => OrganizationModel.fromJson(res.data) as OrganizationModel);
    }

    getWidgetScript(id:EntityIdentifier):Promise<string>{
        return apiService.get<{data:any}>(`/organizations/${id}/embedded-script`).then(res=>{
             return res.data});
    }

    getTextWidgetScript(id:EntityIdentifier):Promise<string>{
        return apiService.get<{data:any}>(`/organizations/${id}/text-widget-script`).then(res=>{
             return res.data});
    }

    list(): Promise<OrganizationModel[]> {
        return apiService.get<{ data: any[] }>('/organizations?include=intents').then(response => response.data.map(j => OrganizationModel.fromJson(j) as OrganizationModel));
    }

    create(data: {
        name: string,
        phone_number:number,
        description: string,
        theme: string | undefined,
        enable_message_button: boolean,
    }): Promise<OrganizationModel> {
        return apiService.post<{ data: any }>('/organizations', data).then(res => OrganizationModel.fromJson(res.data) as OrganizationModel);
    }

    uploadImage(id: EntityIdentifier, organization_image: FormData | undefined): Promise<OrganizationModel> {
        return apiService.put<{ data: any }>(`/organizations/${id}/upload-organization-image`, organization_image, {
            headers: {
                "content-type": "multipart/form-data"
            }
        }).then(res => OrganizationModel.fromJson(res.data) as OrganizationModel);
    }

    uploadMessageIcon(id: EntityIdentifier, message_icon: FormData | undefined): Promise<OrganizationModel> {
        return apiService.put<{ data: any }>(`/organizations/${id}/upload-message-icon`, message_icon, {
            headers: {
                "content-type": "multipart/form-data"
            }
        }).then(res => OrganizationModel.fromJson(res.data) as OrganizationModel);
    }

    uploadCrossButtonIcon(id: EntityIdentifier, cross_button_icon: FormData | undefined): Promise<OrganizationModel> {
        return apiService.put<{ data: any }>(`/organizations/${id}/upload-cross-button-icon`, cross_button_icon, {
            headers: {
                "content-type": "multipart/form-data"
            }
        }).then(res => OrganizationModel.fromJson(res.data) as OrganizationModel);
    }

    update(id: EntityIdentifier, data: {
        name: string,
        phone_number:number
        description: string
        theme: string | undefined
        enable_message_button: boolean
    }): Promise<OrganizationModel> {
        return apiService.put<{ data: any }>(`/organizations/${id}`, data).then(res => OrganizationModel.fromJson(res.data) as OrganizationModel);
    }

    updateEnableMessageButtonState(id: EntityIdentifier, data: {
        enable_message_button: boolean
    }): Promise<OrganizationModel> {
        return apiService.put<{ data: any }>(`/organizations/${id}`, data).then(res => OrganizationModel.fromJson(res.data) as OrganizationModel);
    }

    delete(id: EntityIdentifier): Promise<void> {
        return apiService.delete(`/organizations/${id}`)
    }

    attachIntent(data: {
        intentId: EntityIdentifier,
        organisationId: EntityIdentifier
    }): Promise<void> {
        return apiService.post('/intents/attach', data)
    }

    detachIntent(data: {
        intentId: EntityIdentifier,
        organisationId: EntityIdentifier
    }): Promise<void> {
        return apiService.delete('/intents/detach', data)
    }
}

export const organisationService = OrganisationService.getInstance();
