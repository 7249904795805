import React, {useEffect} from 'react'
import {RouteComponentProps} from "react-router";
import {CircularProgress, Divider, Icon, IconButton, Snackbar, SnackbarContent} from "@material-ui/core";
import IntentCard from "../IntentCard/IntentCard";
import {inject, observer} from "mobx-react";
import {Close} from "@material-ui/icons";
import {IntentStore} from '../../stores/IntentStore';
import CreateIntentDialog from "../CreateIntentDialog/CreateIntentDialog";
import {IntentModel} from '../../models/Intent';
import Typography from "@material-ui/core/Typography";
import SearchBar from "../customs/search-bar/SearchBar";
import './Intent.scss';
import {OrganizationStore} from "../../stores/OrganizationStore";
import {runInAction} from "mobx";
import {OrganizationModel} from "../../models/Organization";

const BASE_INTENT = true;
const GLOBAL_INTENT = true;
const ORGANIZATION_INTENT = false;
const FALSE_ORG_ID = 0;

const Intent: React.FC<RouteComponentProps<{}>> = (props: any) => {
    const intentStore = props.store.intent as IntentStore;
    const intents = intentStore.intents;
    const organizationStore = props.store.organization as OrganizationStore;
    const organizations = organizationStore.organizations;

    const [openIntentDialog, setOpenIntentDialog] = React.useState<boolean>(false);
    const [openCreateIntentDialog, setOpenCreateIntentDialog] = React.useState<boolean>(false);
    const [openCopyIntentDialog, setOpenCopyIntentDialog] = React.useState<boolean>(false);
    const [intentId, setIntentId] = React.useState<number | null>(null);
    const [intentTitle, setIntentTitle] = React.useState<string>('');
    const [intentDesc, setIntentDesc] = React.useState<string>('');
    const [intentdisplayName, setintentdisplayName] = React.useState<string>('');

    useEffect(() => {
        runInAction((() => {
            organizationStore.searchString = '';
            intentStore.searchString = ''
        }))
        intentStore.load();
        organizationStore.load();
    });

    return (
        <div className={'intents'}>
            <div className={'header'}>
                <div className={'heading'}>
                    <Typography variant={"h4"} style={{color: '#2BA5D4'}}>Intents</Typography>
                    <div
                        className={'add-btn'}
                        onClick={() => setOpenCreateIntentDialog(true)}>
                        + Add Intent
                    </div>
                </div>
                <SearchBar onChange={(query) => _handleSearch(query)}/>
            </div>
            {(intentStore.isLoading || organizationStore.isLoading) ? <CircularProgress className={'abs-center'}/> :
                <>
                    <Typography variant={"h4"} style={{color: '#2BA5D4', marginTop: "1em"}}>Global Base
                        Intents</Typography>
                    <div className={'intent-cards'}>
                        {intentStore.searchString.length === 0 ?
                            intents.length ? intents.filter(intent => intent.base_intent).map((intent, index) => showIntent(intent, index, BASE_INTENT, FALSE_ORG_ID)) : intentStore.isLoading ? null :
                                <Typography variant={"h5"} style={{color: '#2BA5D4'}} className={'no-intent'}>No
                                    Intent</Typography> :
                            intents.filter((intent) =>
                                intent.intent.toUpperCase().includes(intentStore.searchString
                                    .toUpperCase())).length ? intents.filter((intent) =>
                                    intent.intent.toUpperCase().includes(intentStore.searchString
                                        .toUpperCase())).filter(intent => intent.base_intent).map((intent, index) => showIntent(intent, index, BASE_INTENT, FALSE_ORG_ID)) :
                                <Typography variant={"h5"} style={{color: '#2BA5D4'}} className={'no-intent'}>No
                                    Intent</Typography>
                        }
                    </div>
                    <Divider className={'divider'}/>

                    <Typography variant={"h4"} style={{color: '#2BA5D4', marginTop: "1em"}}>Global Intents</Typography>
                    <div className={'intent-cards'}>
                        {intentStore.searchString.length === 0 ?
                            intents.length ? intents.filter(intent => !intent.base_intent).map((intent, index) => showIntent(intent, index, GLOBAL_INTENT, FALSE_ORG_ID)) : intentStore.isLoading ? null :
                                <Typography variant={"h5"} style={{color: '#2BA5D4'}} className={'no-intent'}>No
                                    Intent</Typography> :
                            intents.filter((intent) =>
                                intent.intent.toUpperCase().includes(intentStore.searchString
                                    .toUpperCase())).length ? intents.filter((intent) =>
                                    intent.intent.toUpperCase().includes(intentStore.searchString
                                        .toUpperCase())).filter(intent => !intent.base_intent).map((intent, index) => showIntent(intent, index, GLOBAL_INTENT, FALSE_ORG_ID)) :
                                <Typography variant={"h5"} style={{color: '#2BA5D4'}} className={'no-intent'}>No
                                    Intent</Typography>
                        }
                    </div>
                    <Divider className={'divider'}/>

                    <div className={'organizations'}>
                        {organizations.map((org, index) =>
                            <div key={org.id} className={'organization'}>
                                <Typography variant={"h4"} style={{color: '#2BA5D4'}}>{org.name}</Typography>
                                <div className={'intent-cards'}>
                                    {organizationStore.searchString.length === 0 ?
                                        org.intents?.length ? org.intents.map((intent, index) => showIntent(intent, index, ORGANIZATION_INTENT, org.id)) : organizationStore.isLoading ? null :
                                            <Typography variant={"h5"} style={{color: '#2BA5D4'}}
                                                        className={'no-intent'}>No
                                                Intent</Typography> :
                                        org.intents?.filter((intent) =>
                                            intent.intent.toUpperCase().includes(organizationStore.searchString
                                                .toUpperCase())).length ? org.intents.filter((intent) =>
                                                intent.intent.toUpperCase().includes(organizationStore.searchString
                                                    .toUpperCase())).map((intent, index) => showIntent(intent, index, ORGANIZATION_INTENT, org.id)) :
                                            <Typography variant={"h5"} style={{color: '#2BA5D4'}}
                                                        className={'no-intent'}>No
                                                Intent</Typography>}
                                </div>
                                {index !== organizations.length - 1 && <Divider className={'divider'}/>}
                            </div>
                        )}
                    </div>
                </>
            }
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={intentStore.isOpen}
                autoHideDuration={600}
            >
                <SnackbarContent
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar">
                            <Icon/>
                            {intentStore.errorMessage}
                        </span>
                    }
                    action={[
                        <IconButton key="close" aria-label="close" color="inherit" onClick={intentStore.closeSnackBar}>
                            <Close/>
                        </IconButton>,
                    ]}
                />
            </Snackbar>
            <CreateIntentDialog
                open={openIntentDialog || openCreateIntentDialog || openCopyIntentDialog}
                onClose={
                    () => {
                        setOpenIntentDialog(false);
                        setOpenCreateIntentDialog(false);
                        setOpenCopyIntentDialog(false);
                        intentStore.isOpen = false;
                        intentStore.errorMessage = "";
                    }
                }
                title={openCreateIntentDialog ? 'Create Intent' : openCopyIntentDialog ? 'Copy Intent' : 'Update Intent'}
                isUpdate={openIntentDialog}
                isCopy={openCopyIntentDialog}
                isCreate={openCreateIntentDialog}
                intentId={openIntentDialog || openCopyIntentDialog ? intentId : null}
                intentTitle={openIntentDialog ? intentTitle : ''}
                intentDesc={openIntentDialog ? intentDesc : ''}
                intentDisplayName={openIntentDialog ? intentdisplayName : ''}
            />
        </div>
    );

    function editIntent(event: Event, intentId: number, title: string, desc: string, intentDisplayName: string) {
        setOpenIntentDialog(true);
        setIntentId(intentId);
        setIntentTitle(title);
        setIntentDesc(desc);
        setintentdisplayName(intentDisplayName);
    }

    function copyIntent(event: Event, intentId: number, title: string, desc: string, intentDisplayName: string) {
        setOpenCopyIntentDialog(true);
        setIntentId(intentId);
        setIntentTitle(title);
        setIntentDesc(desc);
        setintentdisplayName(intentDisplayName);
    }

    function showIntent(intent: IntentModel, index: number, type: boolean, orgId: number) {
        return <IntentCard
            key={intent.id}
            intentType={type}
            intentTitle={intent.display_name}
            intentDesc={intent.description}
            onDelete={() => {
                _handleDeleteIntent(orgId, intent, type)
            }}
            onAdd={() => {
                return type ? props.history.push(`/${intent.id}/questions`)
                    :
                    props.history.push(`/organization/${+orgId}/intents/${intent.id}/questions`)
            }
            }
            intent={intent}
            onEditIntent={editIntent}
            onCopyIntent={copyIntent}
        />
    }

    function _handleSearch(query: string) {
        intentStore.updateSearchString(query);
        organizationStore.updateSearchString(query)
    }

    function _handleDeleteIntent(orgId: number, intent: IntentModel, type: boolean) {
        (orgId === 0) ?
            intentStore.delete(+intent.id)
            :
            organizationStore.detachIntent(intent, +orgId);

        if (type) {
            organizations.map((org: OrganizationModel) => {
                if (org.intents && org.intents.length > 0) {
                    org.intents = org.intents.filter((intentItem: IntentModel) => intentItem.id !== intent.id)
                }
            return null;
            })
        }
    }
};

export default inject("store")(observer(Intent));
