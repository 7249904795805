import React from 'react';

import Login from '../login/Login';
import Logout from '../Logout/Logout'
import ProtectedRoute from '../../guards/ProtectedRoute';
import PublicRoute from '../../guards/PublicRoute';
import Home from '../Home/Home';
import {Switch} from 'react-router';
import {useHistory} from "react-router-dom";
import * as Axios from "axios";

const RootWidget: React.FC = () => {
    const history = useHistory();

    const errorHandler = (error: any) => {
        // Handle errors
        if (error.response.status === 401) {
            history.push('/logout');
        }

        return Promise.reject(error);
    };

    React.useEffect(() => {
        Axios.default.interceptors.response.use(
            response => Promise.resolve(response),
            error => errorHandler(error)
        )
    });

    return (
        <>
            <Switch>
                <PublicRoute exact component={Login} path={'/login'}/>
                <ProtectedRoute exact component={Logout} path={'/logout'}/>
                <ProtectedRoute path={"/"} component={Home}/>
            </Switch>
        </>
    );
};

export default RootWidget;
