import Model from './Model';
import {observable} from 'mobx';
import {OrganizationStore} from "../stores/OrganizationStore";
import {IntentModel} from "./Intent";

export class OrganizationModel extends Model {
    static _store: OrganizationStore;

    @observable id!: number;
    @observable name!: string;
    @observable logo!: string;
    @observable message_icon!: string;
    @observable cross_button_icon!: string;
    @observable phone_number!:number;
    @observable theme?: string;
    @observable description?: string;
    @observable enable_message_button!: boolean;
    @observable created_at!: string;
    @observable updated_at!: string;

    @observable intents?: IntentModel[];

    getId(): string | number {
        return this.id;
    }
}
