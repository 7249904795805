import React from 'react';
import {ThemeProvider} from '@material-ui/styles';
import {createMuiTheme} from '@material-ui/core';
import {Provider} from 'mobx-react';
import {DndProvider} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import {BrowserRouter} from 'react-router-dom';

import RootWidget from "../RootWidget/RootWidget";
import {RootStore} from '../../stores/RootStore';

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <Provider store={RootStore.getInstance()}>
                <ThemeProvider theme={theme}>
                    <DndProvider backend={HTML5Backend}>
                        <RootWidget/>
                    </DndProvider>
                </ThemeProvider>
            </Provider>
        </BrowserRouter>
    );
};


const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#51C1E4'
        },
        secondary: {
            main: '#fafafa'
        },
        error: {
            main: '#ce1126'
        }
    },
    props: {
        MuiIconButton: {
            color: 'primary'
        }
    }
});


export default App;
